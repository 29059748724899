@import '../../../../styles/variables';


.input-login-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    label {
        width: max-content;
        font-size: .8rem;
        color: var(--text-color);
    }

    .input-login {
        width: 75%;
        position: relative;

        input {
            width: 100%;

            background-color: #FBFBFB;
            color: black;
            box-shadow: var(--input-shadow);
            border-radius: 10px;
            padding: 5px 10px;

            border: none;
            outline: none;
        }

        .input-error {
            border: 2px solid red;
        }

        .input-error-message {
            position: absolute;
            bottom: -15px;
            left: 10px;
            color: red;
            font-size: .6rem;
        }
    }
}

.input-login-disabled {
    opacity: .5;
    color: black;
}
.banners-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: 10vh;
  grid-auto-flow: dense;
  gap: 0.5rem;
}
@media screen and (min-width: 1600px) {
  .banners-container {
    grid-auto-rows: 12vh;
  }
}
.banners-container .big-banner {
  grid-column: span 5;
  grid-row: span 4;
}
@media screen and (min-width: 1600px) {
  .banners-container .big-banner {
    grid-row: span 4;
  }
}
.banners-container .medium-banner {
  grid-column: span 3;
  grid-row: span 4;
}
@media screen and (min-width: 1600px) {
  .banners-container .medium-banner {
    grid-row: span 4;
  }
}
.banners-container .small-banner,
.banners-container .exchangerate-card {
  grid-column: span 4;
  grid-row: span 2;
}
@media screen and (min-width: 1600px) {
  .banners-container .small-banner,
  .banners-container .exchangerate-card {
    grid-row: span 2;
  }
}
@media screen and (max-width: 768px) {
  .banners-container .big-banner,
  .banners-container .exchangerate-card,
  .banners-container .medium-banner,
  .banners-container .small-banner {
    grid-column: span 12;
  }
}
.banners-container .card {
  width: 45%;
  height: 20vh;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=bannersContainer.css.map */
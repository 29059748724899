.breadcrumbs-container {
  width: 100%;
  height: 100%;
}
.breadcrumbs-container ol {
  list-style-type: none;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.breadcrumbs-container ol li,
.breadcrumbs-container ol a {
  text-decoration: none;
  color: var(--text-color);
  font-size: 0.8rem;
  font-weight: 700;
}
.breadcrumbs-container ol i {
  margin: 0 0.2rem;
}/*# sourceMappingURL=breadCrumbs.css.map */
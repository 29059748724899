.homepage-container {
  height: var(--main-height);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  padding-bottom: 0.1rem;
}
.homepage-container .homepage-detail-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 0.5rem;
}
.homepage-container .homepage-detail-cards .card {
  height: calc(20vh - 0.5rem);
}
.homepage-container .homepage-tables {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: auto;
  grid-gap: 0.5rem;
}
@media (min-width: 1700px) {
  .homepage-container .homepage-tables {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
@media (min-width: 1700px) {
  .homepage-container .homepage-tables {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }
}
.homepage-container .homepage-tables .table {
  height: calc(var(--main-height) - 40vh - 0.5rem);
}
@media (max-width: 400px) {
  .homepage-container .homepage-tables .table {
    width: 300px;
  }
}/*# sourceMappingURL=homePage.css.map */
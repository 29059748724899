.tender-add-supplier-container {
  flex: 1;
  height: var(--main-height);
  padding: 1rem;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.tender-add-supplier-container .tender-add-supplier-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.tender-add-supplier-container .tender-add-supplier-top .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.tender-add-supplier-container .tender-add-supplier-top .title-box .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.tender-add-supplier-container .tender-add-supplier-top .tender-add-supplier-top-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.tender-add-supplier-container .tender-add-supplier-top .tender-add-supplier-top-right button {
  background-color: rgb(63, 145, 238);
  color: white;
}
.tender-add-supplier-container .tender-add-supplier-table {
  flex: 1;
  overflow: auto;
}
.tender-add-supplier-container .tender-add-supplier-table .select-table-container .table-box table thead tr {
  background-color: rgb(255, 255, 255);
}/*# sourceMappingURL=tenderAddSupplier.css.map */
        @import "../../../styles/variables";

        // Talep kutusu bileşeni stil ve tasarımı
        .admin-ticket-box {
            width: 99%;
            margin-bottom: 24px;
            padding: 16px;

            background-color: #e4f6fc;
            background-color: #c4c4c4b7;

            border-radius: var(--button-radius);
            box-shadow: var(--box-shadow);

            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include text-font-size;

            .ticket-status-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                gap: 1rem;
            }

            .ticket-status {
                font-weight: bold;
            }

            .admin-reply,
            .admin-reply-form {
                margin-top: 16px;
                background-color: #fff;
                padding: 16px;
                border-radius: var(--button-radius);


                h4 {
                    margin-bottom: 8px;
                }

                p {
                    margin-bottom: 16px;
                }
            }

            .admin-reply-form {

                display: flex;
                flex-direction: column;
                align-items: flex-start;

                textarea {
                    @include input-box;
                    border: none;
                    outline: none;
                    padding: .3rem .5rem;
                    font-size: .9rem;
                    margin-bottom: .5rem;
                    width: 100%;
                }
            }


        }

        .photo-modal-container {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.397);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1020;

            .photo-box {
                width: 80%;
                height: 80%;
                border-radius: 10px;
                overflow: hidden;
                border: 5px solid black;
                background-color: black;
                position: relative;

                .ticket-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .close-button {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    font-size: .9rem;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:hover {
                        cursor: pointer;
                        opacity: .7;
                    }
                }
            }

        }
@import '../../../styles/variables';

/* OFFERS */
.offers-main {
    height: var(--main-height);
    display: grid;
    grid-template-rows: 60px 40px calc(var(--main-height) - 100px);
    grid-template-areas:
        "edit-box"
        "page-link"
        "company-table"
    ;
    overflow: auto;

    @media (max-width:768px) {
        grid-template-rows: 150px 40px calc(var(--main-height) - 190px);
    }

    .offers-edit-box {
        // border: 1px solid black;
        grid-area: edit-box;
        width: 100%;
    }

    .offers-page-link {
        grid-area: page-link;
        width: 100%;
        padding-left: .5rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .offers-table {
        grid-area: company-table;
        width: 100%;
    }

}
.button-white {
  background-color: rgba(251, 251, 251, 0.2117647059);
  color: rgba(0, 0, 0, 0.589);
  box-shadow: 10px 10px 12px -6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  padding: 0.3rem;
  flex: 1;
  font-weight: 700;
  font-size: 15px;
}
.button-white:hover {
  cursor: pointer;
  background-color: #fbfbfb;
  color: rgb(0, 0, 0);
}/*# sourceMappingURL=materiel.css.map */
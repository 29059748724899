.breadcrumbs-container {
    width: 100%;
    height: 100%;

    ol {
        list-style-type: none;

        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        li,
        a {
            text-decoration: none;
            color: var(--text-color);
            font-size: .8rem;
            font-weight: 700;
        }

        i {
            margin: 0 .2rem;
        }
    }
}
@import "../../styles/variables";

.support-modal-container {


    .white-button,
    select {
        background-color: #535B6A;
        color: white;
    }
}
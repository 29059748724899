:root {
  --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
  --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
  --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
  --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-color-light: #535B6A;
  --text-color-dark: #C8C8C8;
  --text-hover-color-light: #344250;
  --text-hover-color-dark: #FBFBFB;
  --table-select-color-light: #FBFBFB;
  --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);
  --pagination-color-dark: #344250;
  --pagination-color-light: #FBFBFB;
  --button-background-color-light: #fbfbfbe4;
  --button-background-color-dark: #535b6abe;
  --button-background-hover-color-dark: #535B6A;
  --button-background-hover-color-light: #FBFBFB;
  --button-color-light: #FBFBFB;
  --button-color-dark: #535B6A;
  --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
  --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);
  --button-radius: 10px;
  --box-radius: 20px;
  --logo-radius: 10px;
  --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
  --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);
  --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;
  --select-background-color-dark: #535B6A;
  --select-background-color-light: #FBFBFB;
  --select-color-dark: #535B6A;
  --select-color-light: #FBFBFB;
  --scroll-color: #888888;
}

.light-theme-styles {
  --box-background-color: var(--box-background-color-light);
  --box-shadow: var(--box-shadow-light);
  --text-color: var(--text-color-light);
  --text-hover-color: var(--text-hover-color-light);
  --text-shadow: var(--text-shadow-light);
  --button-background-color: var(--button-background-color-light);
  --button-background-hover-color: var(--button-background-hover-color-light);
  --button-color: var(--button-color-dark);
  --button-box-shadow: var(var(--button-box-shadow-light));
  --table-select-color: var(--table-select-color-light);
  --input-shadow: var(--input-shadow-light);
  --select-background-color: var(--select-background-color-dark);
  --select-color: var(--select-color-light);
  --grey-border: var(--grey-border-dark);
}

.dark-theme-styles {
  --box-background-color: var(--box-background-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --text-color: var(--text-color-dark);
  --text-hover-color: var(--text-hover-color-dark);
  --text-shadow: var(--text-shadow-dark);
  --button-background-color: var(--button-background-color-dark);
  --button-background-hover-color: var(--button-background-hover-color-dark);
  --button-color: var(--button-color-light);
  --button-box-shadow: var(var(--button-box-shadow-dark));
  --table-select-color: var(--table-select-color-dark);
  --input-shadow: var(--input-shadow-dark);
  --select-background-color: var(--select-background-color-light);
  --select-color: var(--select-color-dark);
  --grey-border: var(--grey-border-light);
}

.table-null-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
}
@media (max-width: 1400px) {
  .table-null-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .table-null-text {
    font-size: 0.8rem;
  }
}

.disabled-box {
  pointer-events: none;
  opacity: 0.6;
}

/* Scroll stil */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.user-edit-card-container {
  width: 100%;
  height: var(--main-height);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
}
@media screen and (max-width: 1028px) {
  .user-edit-card-container {
    height: -moz-max-content;
    height: max-content;
  }
}
.user-edit-card-container .info-edit-container {
  width: 100%;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  padding: 0.6rem;
  overflow: auto;
}
.user-edit-card-container .info-edit-container .top-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.user-edit-card-container .info-edit-container .top-box .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.user-edit-card-container .info-edit-container .top-box .title-box button {
  padding: 0.5rem;
}
.user-edit-card-container .info-edit-container .top-box .title-box .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.user-edit-card-container .info-edit-container .top-box .button-group button {
  width: -moz-max-content;
  width: max-content;
  padding: 0.5rem;
  gap: 0.3rem;
}
.user-edit-card-container .info-edit-container .top-box .button-group button i {
  padding: 0;
}
.user-edit-card-container .info-edit-container .main-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.user-edit-card-container .info-edit-container .main-box .image-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-box {
  width: 150px;
  height: 140px;
  overflow: hidden;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 0.2rem;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-box .image-detail {
  display: none;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-box:hover .image-detail {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 300px;
  height: 300px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: var(--button-radius);
  transition: all 0.3s ease;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.2rem;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-buttons button {
  flex: 1;
  height: 0;
  padding: 0.8rem;
  font-size: 0.7rem;
  position: relative;
  overflow: hidden;
  background-color: var(--select-background-color);
  color: var(--select-color);
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-buttons button label,
.user-edit-card-container .info-edit-container .main-box .image-container .image-buttons button span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.user-edit-card-container .info-edit-container .main-box .image-container .image-buttons button label:hover,
.user-edit-card-container .info-edit-container .main-box .image-container .image-buttons button span:hover {
  cursor: pointer;
}
.user-edit-card-container .info-edit-container .main-box .input-container {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.user-edit-card-container .info-edit-container .main-box .input-container input,
.user-edit-card-container .info-edit-container .main-box .input-container label {
  font-size: 0.7rem;
}
.user-edit-card-container .info-edit-container .main-box .input-container label {
  color: var(--text-color);
}
.user-edit-card-container .info-edit-container .main-box .input-container input {
  padding: 0.2rem 0.8rem;
}
.user-edit-card-container .info-edit-container .main-box .input-container .red-input label {
  color: #CC4A4B;
}
.user-edit-card-container .info-edit-container .main-box .input-container .red-input input {
  background-color: #CC4A4B;
  color: white;
}
.user-edit-card-container .info-edit-container .main-box .input-container .red-input input::-moz-placeholder {
  color: white;
}
.user-edit-card-container .info-edit-container .main-box .input-container .red-input input::placeholder {
  color: white;
}
.user-edit-card-container .change-pass-container {
  width: 100%;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  padding: 0.6rem;
  overflow: auto;
  flex: 1;
}
.user-edit-card-container .change-pass-container .top-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.user-edit-card-container .change-pass-container .top-box .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.user-edit-card-container .change-pass-container .top-box .title-box .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.user-edit-card-container .change-pass-container .main-box {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.user-edit-card-container .change-pass-container .main-box input,
.user-edit-card-container .change-pass-container .main-box label {
  font-size: 0.7rem;
}
.user-edit-card-container .change-pass-container .main-box label {
  color: var(--text-color);
}
.user-edit-card-container .change-pass-container .main-box input {
  padding: 0.2rem 0.8rem;
  outline: none;
}
.user-edit-card-container .change-pass-container .main-box button {
  height: 0;
  width: 100%;
  padding: 0.8rem;
  margin-top: 0.2rem;
  font-size: 0.7rem;
  background-color: var(--select-background-color);
  color: var(--select-color);
}
.user-edit-card-container .change-pass-container .main-box button span {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=userEditCard.css.map */
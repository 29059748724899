.banners-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 10vh;
    grid-auto-flow: dense;
    
    gap: 0.5rem;
    @media screen and (min-width: 1600px) {
        grid-auto-rows: 12vh;
    }


    .big-banner {
        grid-column: span 5;
        grid-row: span 4;

        @media screen and (min-width: 1600px) {
            grid-row: span 4;
        }

    }

    .medium-banner {
        grid-column: span 3;
        grid-row: span 4;

        @media screen and (min-width: 1600px) {
            grid-row: span 4;
        }
    }

    .small-banner,
    .exchangerate-card {
        grid-column: span 4;
        grid-row: span 2;

        @media screen and (min-width: 1600px) {
            grid-row: span 2;
        }

    }

    @media screen and (max-width: 768px) {

        .big-banner,
        .exchangerate-card,
        .medium-banner,
        .small-banner {
            grid-column: span 12;
        }
    }

    .card {
        width: 45%;
        height: calc(20vh);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
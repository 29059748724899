@import '../../../styles/variables';

.opportunities-layout {
    height: var(--main-height);
}

.opportunities-container {
    // border: 2px solid red;
    width: 100%;
    height: var(--main-height);
    display: grid;
    grid-template-rows: 60px 40px calc(var(--main-height) - 100px);
    grid-template-areas:
        "opportunities-title"
        "opportunities-link"
        "opportunities-table"
    ;

    overflow: auto;

    @media (max-width:768px) {
        grid-template-rows: 100px 40px calc(var(--main-height) - 140px);
    }


    .opportunities-title-and-edit-buttons {
        // border: 2px solid black;
        grid-area: opportunities-title;
    }

    .opportunities-link-groups {
        // border: 2px solid blue;
        grid-area: opportunities-link;
        padding-left: .5rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        
    }

    .opportunities-table {
        grid-area: opportunities-table;
        overflow: hidden;

    }
}
.rev-des-close-btn {
    border: none;
    background: none;
    float: right;

    i {
        font-size: 2rem;
        cursor: pointer;
        color: red;
    }
}

.rev-des-title {
    margin-bottom: 1rem;
}

.rev-des-note {
    background-color: rgb(235, 235, 235);
    padding: .5rem;
}
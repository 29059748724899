.white-button {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: .3rem;
    // padding: 10px;

    // width: max-content;
    // height: 36px;
    border: none;

    border-radius: var(--button-radius);
    background-color: var(--button-background-color);
    color: var(--button-color);
    box-shadow: var(--box-shadow);
    // box-shadow: var(--button-box-shadow);

    // font-weight: 600;

    .white-button-icon {
        // width: max-content;
        display: flex;
        justify-content: center;
        align-items: center;
        
    }

    .white-button-text {
        width: max-content;
        // display: none;
    }

    &:hover {
        font-weight: 900;
        // color: #344250;
        // background-color: rgb(255, 255, 255);

        i {
            font-weight: 900;
            // color: #303a44;
            transform: scale(1.1, 1.1);
        }

    }

}

.white-button-cursor {
    cursor: pointer;

}

.white-button-disabled {
    cursor: no-drop;
    color: var(--button-color);
    background-color: var(--button-background-color);
    border-radius: var(--button-radius);
    opacity: 0.9;
    // z-index: 0;
}
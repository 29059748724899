.settings-container {
    height: 100%;

    form {
        height: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        overflow: auto;

        .app-settings,
        .theme-settings {
            flex: 1;

            min-width: 400px;

            border: var(--grey-border);
            border-radius: 10px;
            padding: 1rem;

            h4 {
                display: flex;
                flex-direction: row;
                align-items: center;

                color: var(--text-color);
                font-weight: 800;
                margin-bottom: 1rem;

                text-shadow: var(--text-shadow);

                svg {
                    width: 20px;
                    height: 20px;
                    fill: var(--text-color);
                    margin-right: .5rem;

                    filter: drop-shadow(10px 10px 12px rgba(86, 88, 92, 0.836));
                }
            }


            .settings-boxes {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                /* Başlıklar */
                h5 {
                    color: var(--text-color);
                    font-size: .85rem;
                    font-weight: 500;
                }

                .settings-inputs {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    padding: .5rem;

                    color: var(--text-color);

                }
            }

            hr {
                border: 2px solid var(--text-color);
                height: 15px;
                margin: 0 .3rem;
            }
        }
    }
}
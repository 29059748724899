@import "../../styles/variables";

.alltenders-container {
    height: 100%;
    display: grid;
    grid-template-rows: 60px calc(var(--main-height) - 60px - .5rem);
    grid-template-areas:
        "edit-box"
        "alltenders-main"
    ;
    gap: .5rem;
    overflow: auto;

    .alltenders-edit-box {
        grid-area: edit-box;
        width: 100%;
    }

    .alltenders-main {
        grid-area: alltenders-main;

        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        overflow: auto;
        // padding: 1rem;
    }

}
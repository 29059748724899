@import '../../../../styles/variables';

.supplyprocess-detail-container {
    width: 100%;
    height: var(--main-height);

    display: grid;
    grid-template-rows: 40px calc(var(--main-height) - 40px - .5rem);
    grid-template-areas: "supply-detail-top" "supply-detail-bottom";
    gap: .5rem;

    .supplyprocess-detail-container-top {
        grid-area: supply-detail-top;

        .top-box {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 1rem;

            .supplyprocess-detail-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;
                color: var(--text-color);
            }

            .supplyprocess-edit-buttons {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;
            }
        }
    }

    .supplyprocess-detail-container-bottom {
        grid-area: supply-detail-bottom;

        width: 100%;
        height: calc(var(--main-height) - 40px - .5rem);

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        overflow: auto;

        .supplyprocess-tender-detail {
            min-width: 400px;
            height:  calc(var(--main-height) - 40px - .5rem);

            @media (max-width:1028px) {
                width: 100%;
                min-width: 100%;
            }
        }

        .supplyprocess-offer-detail {
            height:  calc(var(--main-height) - 40px - .5rem);
            flex: 1;

            @media (max-width:1028px) {
                width: 100%;padding-bottom: .1rem;
            }
        }
    }

}
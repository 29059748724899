@import "../variables";

.layout-container {
    width: 100%;
    height: 100vh;
    padding: 15px;

    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .navbar {
        height: 80px;
    }

    .contents-container {
        height: calc(100vh - 80px - 30px - 1rem);
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .sidebar {
            transition: width 0.3s ease-in-out;

            @media (max-width: 768px) {
                width: 0;
                position: fixed;
                top: calc(80px + 1rem);
                left: 1rem;
                right: 0;
                bottom: 1rem;
                z-index: 999;
                padding-top: 1rem;
            }
        }

        .open-sidebar {
            width: 60px;
            left: -300px;
        }

        .close-sidebar {
            width: 216px;
        }

        .main {
            flex: 1;

            display: grid;
            grid-template-rows: var(--main-height) 20px;
            grid-template-areas:
                "maincontainer"
                "breadcrumbs"
            ;
            gap: .5rem;

            background: var(--box-background-color);
            box-shadow: var(--box-shadow);
            border-radius: var(--box-radius);

            padding: 1rem;
            overflow: auto;

            .main-container {
                grid-area: maincontainer;
                overflow: hidden;
            }

            .breadcrumbs {
                grid-area: breadcrumbs;
            }

        }
    }

}
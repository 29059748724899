.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(121, 121, 121, 0.334);
  z-index: 3;
}
.loading-container img {
  width: 150px;
  animation: blink-2 infinite ease-in-out 1.5s;
}

@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=loading.css.map */
@import '../../../../styles/variables';

.tender-detail-offer-container {
    position: relative;

    width: 100%;
    height: var(--main-height);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    overflow: auto;

    @media (max-width:1028px) {
        width: 100%;
    }

    .opportunities-tender-detail {
        min-width: 400px;
        height: var(--main-height);

        @media (max-width:1028px) {
            width: 100%;
            min-width: 100%;
        }
    }

    .tender-detail-offer-form-panel {
        height: var(--main-height);
        flex: 1;

        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: 1rem 0;
        overflow: hidden;

        .tender-detail-top,
        .tender-detail-offer-form {
            padding: 0 1rem;
        }

        .tender-detail-top {
            height: 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;

            margin-bottom: .7rem;

            .tender-detail-top-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .3rem;

                color: var(--text-color);
                font-size: .9rem;
            }

            .tender-detail-top-right {

                .tender-detail-top-edit-button {
                    padding: .3rem;

                    span {
                        font-size: .8rem;
                    }
                }
            }
        }

    }

}

.link-active {
    background-color: white;
    box-shadow: $box-shadow;
    color: $text-color-dark;
    font-weight: 600;
}

.link-not-active {
    background: transparent;

}

.button-white-active {
    cursor: pointer;
}

.button-white-disabled {
    opacity: .5;
    cursor: no-drop;
}
@import '../../../../styles/variables';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.tender-offer-detail-container {
    // width: 100%;
    // display: grid;
    // grid-template-columns: 400px 1fr;
    // grid-template-rows: calc(100vh - 80px - 72px - 1.1rem - 20px - 2rem);
    // grid-template-areas: "tender-offer-detail-tender tender-offer-detail";
    // gap: 1rem;

    // position: relative;

    position: relative;
    width: 100%;
    height: var(--main-height);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    

    overflow: auto;

    @media (max-width:1028px) {
        width: 100%;
        padding-bottom: .1rem;
    }

    .tender-detail {
        // grid-area: tender-offer-detail-tender;
        min-width: 400px;
        height: var(--main-height);

        @media (max-width:1028px) {
            width: 100%;
            min-width: 100%;
        }
    }

    .tender-offer-detail {
        // grid-area: tender-offer-detail;
        height: var(--main-height);

        flex: 1;
    }

}
.rating-container {
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.562);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rating-container .rating-main {
  background-color: rgb(255, 255, 255);
  padding: 1.5rem;
  border-radius: var(--box-radius);
  min-width: 400px;
}
.rating-container .rating-main h3 {
  margin-bottom: 10px;
}
.rating-container .rating-main .rating-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.rating-container .rating-main .rating-item label {
  margin-right: 10px;
  width: 140px;
}
.rating-container .rating-main .rating-item input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.rating-container .rating-main button {
  padding: 10px 20px;
  background-color: #337ab7;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.rating-container .rating-main button:hover {
  background-color: #23527c;
}/*# sourceMappingURL=ratingComponent.css.map */
@import '../../../styles/variables';

.companylist-container {
    height: 100%;
    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
        "edit-box"
        "company-table"
    ;
    gap: .5rem;

    .companylist-edit-box {
        // border: 1px solid black;
        grid-area: edit-box;
        width: 100%;
    }

    .companylist-table {
        // border: 1px solid red;
        grid-area: company-table;
        width: 100%;

    }

}

/* COMPANY ADD */
.company-add-container {
    height: 100%;

    display: grid;
    grid-template-rows: 50px 1fr;
    grid-template-areas:
        "edit-container"
        "form-container"
    ;
    gap: .5rem;


    .company-add-edit-box {
        grid-area: edit-container;

        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        .title-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .3rem;

            i {
                color: var(--text-color);
                @include title-icon-font-size;
            }

            .title {
                color: var(--text-color);
                @include title-font-size;
            }
        }
    }

    .company-add-form-container {
        grid-area: form-container;
        width: 100%;
        overflow: auto;
        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: 1rem;

        form {
            max-width: 400px;
            display: flex;
            flex-direction: column;
            gap: .5rem;

            label {
                @include label-input-font-size;
                font-weight: bold;
                color: var(--text-color);
                width: 80px;
            }

            .input-standart {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                label {
                    // width: max-content;
                }

                input {
                    @include label-input-font-size;

                    // width: 70%;
                    flex: 1;
                    outline: none;
                }

            }

            .role-select-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 1rem;

                label {
                    color: var(--text-color);
                    // width: max-content;
                }

                select {
                    @include select-box;
                    @include label-input-font-size;

                    // width: 70%;
                    flex: 1;
                    padding: .3rem;
                }
            }
        }
    }
}
@import '../../../../styles/variables';

.company-detail-container {
    width: 100%;
    height: var(--main-height);

    display: grid;
    grid-template-rows: 40px calc(var(--main-height) - 40px - .5rem);
    grid-template-areas: "company-detail-top" "company-detail-bottom";
    gap: .5rem;

    @media (max-width:768px) {
        grid-template-rows: 110px calc(var(--main-height) - 110px - .5rem);
    }

    .company-detail-container-top {
        grid-area: company-detail-top;
    }

    .company-detail-container-main {
        grid-area: company-detail-bottom;

        width: 100%;
        height: calc(var(--main-height) - 40px - .5rem);

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;

        overflow: auto;

        @media (max-width:1028px) {

            padding-bottom: .1rem;
        }

        .left-container {
            min-width: 400px;
            height: calc(var(--main-height) - 40px - .5rem);

            @media (max-width:1028px) {
                width: 100%;
                min-width: 100%;
                height: max-content;
            }
        }

        .right-container {
            height: calc(var(--main-height) - 40px - .5rem);
            flex: 1;

            border: var(--grey-border);
            border-radius: var(--button-radius);
            display: grid;
            grid-template-rows: 35px 1fr;
            grid-template-areas: "page-link"
                "company-table"
            ;
            gap: 1rem;
            padding: 1rem;

            .right-container-top {
                grid-area: page-link;
                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: .3rem;
                flex-wrap: nowrap;

                .right-container-page-link {

                    button {
                        padding: .2rem .5rem;
                        border-radius: .5rem;
                        border: none;
                        color: var(--button-color);



                        &:hover {
                            cursor: pointer;
                            color: var(--text-hover-color);
                            font-weight: 600;
                        }
                    }

                    .link-active {
                        background-color: var(--button-background-color);
                        box-shadow: var(--box-shadow);
                        font-weight: 600;
                    }

                    .link-not-active {
                        background: transparent;

                    }

                }

                .right-container-edit-buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;
                }
            }

            .right-container-table {
                grid-area: company-table;
                width: 100%;
            }
        }
    }

}
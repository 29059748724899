.banner-card-container {
    border: var(--grey-border);
    border-radius: var(--button-radius);
    width: 100%;
    height: 100%;
    overflow: hidden;
    // animation: slideLeftRight .7s infinite ease-in-out;

    .img-container {
        width: 100%;
        height: 100%;
        background-color: rgb(0, 0, 0);

        img {
            width: 100%;
            height: 100%;
            // object-fit: contain;
            object-fit: cover;
            background-color: rgb(214, 214, 214);
        }
    }
}
// .banner-small {
//     width: 25%;
//     height: 20vh;
    
// }

// .banner-medium {
//     width: 25%;
//     height: 40vh;
    
// }

// .banner-big {
//     width: 50%;
//     height: 40vh;
    
// }

@keyframes slideLeftRight {

    0% {
        border-width: 0px;
    }

    50%{
        border-width: 2px;
    }

    100% {
        border-width: 3px;
    }

}
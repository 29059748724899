@import '../../styles/variables';

.offer-detail-card {
  width: 100%;
  height: 100%;

  border: var(--grey-border);
  border-radius: var(--button-radius);
  padding: 1rem;

  display: flex;
  flex-direction: column;
  gap: .5rem;

  overflow: hidden;

  .offer-detail-card-top {
    height: 35px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: .2rem;
      color: var(--text-color);
    }

    .button-group {

      a {
        text-decoration: none;
      }
    }
  }

  .offer-detail-card-main {
    flex: 1;

    width: 100%;

    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow: hidden;

    .offer-detail-card-images-container {
      // height: 140px;
      height: 20vh;
      width: 100%;

      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: .5rem;

      .no-offer-image {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(250, 250, 248);
        color: gray;
      }

    }

    .offer-detail-card-info-container {

      flex: 1;
      height: 100px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;


      .offer-detail-card-documan-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .detail-text-title {
          width: 30%;
          padding-left: .3rem;
          font-size: .7rem;
          color: var(--text-color);
        }

        .documan-box {
          width: 70%;
          padding-left: 2rem;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;

          .detail-text {
            font-size: .7rem;
            font-weight: 700;
            text-decoration: underline;
            color: var(--text-color);
          }

        }
      }
    }
  }
}
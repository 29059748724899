@charset "UTF-8";
.add-recommed-supplier-table-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  /* Pagination tablodaki grid düzen ayarı */
}
.add-recommed-supplier-table-container .table-box {
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  /* Tablodaki elemanı seçerken oluşan animasyonlar */
}
.add-recommed-supplier-table-container .table-box table {
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.add-recommed-supplier-table-container .table-box table th,
.add-recommed-supplier-table-container .table-box table td {
  text-align: center;
  font-size: 0.9rem;
  padding: 0.5rem 0.8rem;
  white-space: nowrap;
  /* Metnin otomatik olarak satıra sığdırılmasını engeller */
  max-width: 150px;
  overflow: hidden;
  /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
  text-overflow: ellipsis;
  /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */
}
.add-recommed-supplier-table-container .table-box table th .image-box,
.add-recommed-supplier-table-container .table-box table td .image-box {
  width: 50px;
  height: 50px;
  border-radius: var(--logo-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.add-recommed-supplier-table-container .table-box table th .image-box img,
.add-recommed-supplier-table-container .table-box table td .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: rgb(214, 214, 214);
}
.add-recommed-supplier-table-container .table-box table thead {
  width: 100%;
}
.add-recommed-supplier-table-container .table-box table thead tr {
  background: var(--box-background-color);
  color: var(--text-hover-color);
  border-radius: 0.3rem;
  position: sticky;
  top: 0;
  height: 50px;
}
.add-recommed-supplier-table-container .table-box table tbody tr {
  color: var(--text-color);
}
.add-recommed-supplier-table-container .table-box table tbody tr:hover td:first-child {
  border-radius: 10px 0 0 10px;
}
.add-recommed-supplier-table-container .table-box table tbody tr:hover td:last-child {
  border-radius: 0 10px 10px 0;
}
.add-recommed-supplier-table-container .table-box .table-row-select td:first-child {
  border-radius: 10px 0 0 10px;
}
.add-recommed-supplier-table-container .table-box .table-row-select td:last-child {
  border-radius: 0 10px 10px 0;
}
.add-recommed-supplier-table-container .pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
}/*# sourceMappingURL=addRecommedSupplierTable.css.map */
.label-input-info {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.label-input-info label {
  min-width: 100px;
  font-weight: bold;
  color: var(--text-color);
}
@media (max-width: 1028px) {
  .label-input-info label {
    min-width: 70px;
  }
}
@media (max-width: 768px) {
  .label-input-info label {
    min-width: 60px;
  }
}
.label-input-info input {
  width: 45%;
  background-color: #FBFBFB;
  color: black;
  font-weight: 700;
  box-shadow: var(--input-shadow);
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  outline: none;
}
@media (max-width: 1028px) {
  .label-input-info input {
    flex: 1;
  }
}
.label-input-info .label-input-loading {
  position: absolute;
  left: 45%;
  font-weight: bold;
}
.label-input-info span {
  width: 45%;
  color: var(--text-color);
  flex-wrap: wrap;
}
@media (max-width: 1028px) {
  .label-input-info span {
    display: none;
  }
}
.label-input-info .label-input-info-disabled {
  opacity: 0.5;
}
.label-input-info .error-input {
  border: 2px solid red;
}/*# sourceMappingURL=labelInputInfo.css.map */
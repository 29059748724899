.add-recommended-supplier-container {
    height: 100%;
    padding: 1rem;

    border: var(--grey-border);
    border-radius: var(--button-radius);

    display: flex;
    flex-direction: column;
    gap: .5rem;

    .add-recommended-supplier-top-box {
        color: var(--text-color);
    }

    .add-recommended-supplier-table {
        flex: 1;
    }
}
@import '../../../styles/variables';

.basic-table-container {
    width: 100%;
    height: 100%;
    border: var(--grey-border);
    border-radius: var(--button-radius);
    overflow: auto;
    position: relative;

    .basic-table-box {
        padding: 0 1rem;
        width: 100%;
        height: calc(100% - 40px);
        overflow: auto;

        position: absolute;
        top: 0;
        left: 0;


        table {
            width: 100%;
            border-collapse: collapse;
            overflow: auto;

            th,
            td {
                @include text-font-size;
                text-align: center;

                white-space: nowrap;
                /* Metnin otomatik olarak satıra sığdırılmasını engeller */
                max-width: 150px;
                overflow: hidden;
                /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
                text-overflow: ellipsis;

            }

            thead {
                width: 100%;

                tr {
                    // height: 50px;
                    position: sticky;
                    top: 0;
                    background: var(--box-background-color);
                    color: var(--text-hover-color);
                    border-radius: .3rem;

                    th {
                        padding: .5rem;
                    }

                }
            }

            tbody {
                tr {
                    color: var(--text-color);


                    &:hover td:first-child {
                        border-radius: 10px 0 0 10px;
                    }

                    &:hover td:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                    td {
                        padding: .5rem;
                    }

                }
            }

        }

    }


    /* Pagination tablodaki grid düzen ayarı */
    .pagination {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40px;
        padding: 0 .5rem;

        button {
            @include text-font-size;
            width: 1.5rem;
            padding: .1rem;
        }
    }

}
.login-lang-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  gap: 0.5rem;
  background: var(--box-background-color);
  border-radius: var(--box-radius);
  box-shadow: var(--box-shadow);
  overflow: auto;
}
.login-lang-container .lang-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.login-lang-container .lang-button img {
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.login-lang-container .lang-button .text {
  font-size: 0.8rem;
  color: var(--text-color);
}
.login-lang-container .lang-button:hover {
  cursor: pointer;
  opacity: 1;
}
.login-lang-container .lang-button:hover img {
  opacity: 1;
}
.login-lang-container .lang-button:hover .text {
  font-weight: bold;
}
.login-lang-container .active-lang {
  opacity: 1;
  font-weight: bold;
}
.login-lang-container .active-lang img {
  opacity: 1;
}
.login-lang-container .disabled-lang {
  opacity: 0.5;
}
.login-lang-container .disabled-lang img {
  opacity: 0.5;
}/*# sourceMappingURL=langBox.css.map */
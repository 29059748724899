.error-box-container {
    // border: 1px solid red;
    border-radius: var(--button-radius);
    box-shadow: inset 0 0 15px red;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    padding: 1.2em;

    overflow: auto;

    .error-title {
        margin-bottom: 16px;

        h5 {
            font-size: 1em;
            color: #fa1616;
        }

        p {
            font-weight: bold;
            font-size: 1em;
            color: var(--text-color);
        }

    }

    .error-message {

        h5 {
            font-size: 1em;
            color: #fa1616;
        }

        code {
            font-weight: bold;
            font-size: 1em;
            color: var(--text-color);
        }
    }

    // Duyarlı font büyüklükleri için medya sorguları
    @media screen and (max-width: 768px) {
        .error-title h5 {
            font-size: 1.5em;
        }

        .error-title p {
            font-size: 1.2em;
        }
    }

    @media screen and (max-width: 480px) {
        .error-title h5 {
            font-size: 1.2em;
        }

        .error-title p {
            font-size: 1em;
        }
    }
}
:root {
    --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
    --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
    --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
    --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
    --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);

    --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
    --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);

    --text-color-light: #535B6A;
    --text-color-dark: #C8C8C8;
    --text-hover-color-light: #344250;
    --text-hover-color-dark: #FBFBFB;

    --table-select-color-light: #FBFBFB;
    --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);

    --pagination-color-dark: #344250;
    --pagination-color-light: #FBFBFB;

    --button-background-color-light: #fbfbfbe4;
    --button-background-color-dark: #535b6abe;

    --button-background-hover-color-dark: #535B6A;
    --button-background-hover-color-light: #FBFBFB;

    --button-color-light: #FBFBFB;
    --button-color-dark: #535B6A;

    --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
    --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);

    --button-radius: 10px;
    --box-radius: 20px;
    --logo-radius: 10px;

    --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
    --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);

    --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
    --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;

    --select-background-color-dark: #535B6A;
    --select-background-color-light: #FBFBFB;

    --select-color-dark: #535B6A;
    --select-color-light: #FBFBFB;
    --scroll-color: #888888;
}





.light-theme-styles {
    --box-background-color: var(--box-background-color-light);
    --box-shadow: var(--box-shadow-light);
    --text-color: var(--text-color-light);
    --text-hover-color: var(--text-hover-color-light);
    --text-shadow: var(--text-shadow-light);
    --button-background-color: var(--button-background-color-light);
    --button-background-hover-color: var(--button-background-hover-color-light);
    --button-color: var(--button-color-dark);
    --button-box-shadow: var(var(--button-box-shadow-light));
    --table-select-color: var(--table-select-color-light);
    --input-shadow: var(--input-shadow-light);
    --select-background-color: var(--select-background-color-dark);
    --select-color: var(--select-color-light);
    --grey-border: var(--grey-border-dark);

}

.dark-theme-styles {
    --box-background-color: var(--box-background-color-dark);
    --box-shadow: var(--box-shadow-dark);
    --text-color: var(--text-color-dark);
    --text-hover-color: var(--text-hover-color-dark);
    --text-shadow: var(--text-shadow-dark);
    --button-background-color: var(--button-background-color-dark);
    --button-background-hover-color: var(--button-background-hover-color-dark);

    --button-color: var(--button-color-light);
    --button-box-shadow: var(var(--button-box-shadow-dark));
    --table-select-color: var(--table-select-color-dark);
    --input-shadow: var(--input-shadow-dark);
    --select-background-color: var(--select-background-color-light);
    --select-color: var(--select-color-dark);
    --grey-border: var(--grey-border-light);

}

.table-null-text {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--text-color);

    font-size: 1rem;

    @media (max-width:1400px) {
        font-size: .9rem;
    }

    @media (max-width:768px) {
        font-size: .8rem;
    }
}

.disabled-box {
    pointer-events: none;
    opacity: 0.6;
}

// main alanının yüksekliği
$main-container-height: calc(100vh - 80px - 72px - 1.6rem - 40px);

$border-radius: 10px;

$box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.5), 10px 10px 12px -6px rgba(166, 180, 200, 0.5);
$box-bg-color: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);

$box-bg-color-dark: #344250;

$input-bg-color: linear-gradient(134.17deg, #EEF0F5 4.98%, #E6E9EF 94.88%, #E6E9EF 94.88%);
$input-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);

$button-bg-color-light: #ffffff;
$button-bg-color-dark: #344250;
$button-color-light: rgba(0, 0, 0, 0.692);
$button-box-shadow: 10px 10px 12px -6px rgba(0, 0, 0, 0.1);

$text-shadow: -10px -10px 12px rgba(255, 255, 255, 0.5), 10px 10px 12px rgba(166, 180, 200, 0.5);
$text-color-light: #535B6A;
$text-color-dark: #344250;

@mixin title-icon-font-size {
    font-size: 1.3rem;

    @media (max-width:1400px) {
        font-size: 1.2rem;
    }

    @media (max-width:768px) {
        font-size: 1rem;
    }
}

@mixin title-font-size {
    font-size: 1rem;

    @media (max-width:1400px) {
        font-size: .9rem;
    }

    @media (max-width:768px) {
        font-size: .8rem;
    }
}

@mixin text-font-size {
    font-size: .9rem;

    @media (max-width:1400px) {
        font-size: .75rem;
    }

    @media (max-width:768px) {
        font-size: .65rem;
    }
}

@mixin label-input-font-size {
    font-size: .8rem;

    @media (max-width:1400px) {
        font-size: .7rem;
    }

    @media (max-width:768px) {
        font-size: .6rem;
    }
}

@mixin select-box {
    cursor: pointer;
    background-color: var(--select-background-color);
    color: var(--select-color);
    border: none;
    outline: none;
    border-radius: var(--button-radius);
    box-shadow: var(--box-shadow);
    padding: .2rem .5rem;
}

@mixin form-label {
    font-size: .8rem;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.651);
}

@mixin button-light {
    background-color: $button-bg-color-light;
    color: $button-color-light;
    box-shadow: $button-box-shadow;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: .5rem;
    font-weight: 700;
}

@mixin image-cover {
    width: 50px;
    height: 50px;
    object-fit: fill;
    overflow: hidden;
}

@mixin shadow-box-style {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

@mixin light-box {
    background: $box-bg-color;
    box-shadow: $box-shadow;
    border-radius: 20px;
}

@mixin background-image($url) {
    background-image: url($url);

    background-repeat: no-repeat;
    background-size: cover;
}

@mixin input-box {
    background: $input-bg-color;
    box-shadow: $input-shadow;
    border-radius: 10px;
}

@mixin input-box-light {
    background: white;
    outline: none;
    border: none;
    box-shadow: $input-shadow;
    border-radius: 10px;
}

/* Scroll stil */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}

::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}
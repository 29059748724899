 @import '../../../../styles/variables';

 /* TENDERS */
 .tenders-container {
     height: var(--main-height);
     display: grid;
     grid-template-rows: 60px 40px calc(var(--main-height) - 100px);
     grid-template-areas:
         "tenders-title"
         "tenders-link"
         "tenders-table"
     ;
     overflow: auto;

     @media (max-width:768px) {
        grid-template-rows: 140px 40px calc(var(--main-height) - 180px);

    }

     .tenders-edit-box {
         grid-area: tenders-title;
     }

     .tenders-page-link {
         grid-area: tenders-link;
         padding-left: .5rem;
         display: flex;
         justify-content: flex-start;
         align-items: flex-end;
     }

     .tenders-table {
         grid-area: tenders-table;
     }


     .disabled-box {
         opacity: .5;
         pointer-events: none;
     }

 }
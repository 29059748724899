@charset "UTF-8";
:root {
  --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
  --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
  --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
  --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-color-light: #535B6A;
  --text-color-dark: #C8C8C8;
  --text-hover-color-light: #344250;
  --text-hover-color-dark: #FBFBFB;
  --table-select-color-light: #FBFBFB;
  --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);
  --pagination-color-dark: #344250;
  --pagination-color-light: #FBFBFB;
  --button-background-color-light: #fbfbfbe4;
  --button-background-color-dark: #535b6abe;
  --button-background-hover-color-dark: #535B6A;
  --button-background-hover-color-light: #FBFBFB;
  --button-color-light: #FBFBFB;
  --button-color-dark: #535B6A;
  --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
  --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);
  --button-radius: 10px;
  --box-radius: 20px;
  --logo-radius: 10px;
  --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
  --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);
  --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;
  --select-background-color-dark: #535B6A;
  --select-background-color-light: #FBFBFB;
  --select-color-dark: #535B6A;
  --select-color-light: #FBFBFB;
  --scroll-color: #888888;
}

.light-theme-styles {
  --box-background-color: var(--box-background-color-light);
  --box-shadow: var(--box-shadow-light);
  --text-color: var(--text-color-light);
  --text-hover-color: var(--text-hover-color-light);
  --text-shadow: var(--text-shadow-light);
  --button-background-color: var(--button-background-color-light);
  --button-background-hover-color: var(--button-background-hover-color-light);
  --button-color: var(--button-color-dark);
  --button-box-shadow: var(var(--button-box-shadow-light));
  --table-select-color: var(--table-select-color-light);
  --input-shadow: var(--input-shadow-light);
  --select-background-color: var(--select-background-color-dark);
  --select-color: var(--select-color-light);
  --grey-border: var(--grey-border-dark);
}

.dark-theme-styles {
  --box-background-color: var(--box-background-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --text-color: var(--text-color-dark);
  --text-hover-color: var(--text-hover-color-dark);
  --text-shadow: var(--text-shadow-dark);
  --button-background-color: var(--button-background-color-dark);
  --button-background-hover-color: var(--button-background-hover-color-dark);
  --button-color: var(--button-color-light);
  --button-box-shadow: var(var(--button-box-shadow-dark));
  --table-select-color: var(--table-select-color-dark);
  --input-shadow: var(--input-shadow-dark);
  --select-background-color: var(--select-background-color-light);
  --select-color: var(--select-color-dark);
  --grey-border: var(--grey-border-light);
}

.table-null-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
}
@media (max-width: 1400px) {
  .table-null-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .table-null-text {
    font-size: 0.8rem;
  }
}

.disabled-box {
  pointer-events: none;
  opacity: 0.6;
}

/* Scroll stil */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.sidebar-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  background: var(--box-background-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--box-radius);
  padding-top: 0.5rem;
}
.sidebar-container ul {
  list-style-type: none;
  padding: 1rem;
  padding-top: 0;
  overflow: auto;
}
.sidebar-container ul li {
  margin: 1rem 0;
}
.sidebar-container ul li:hover {
  cursor: pointer;
}
.sidebar-container ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0.3rem;
  text-decoration: none;
  color: var(--text-color);
  /* Icons */
}
.sidebar-container ul li a:hover span, .sidebar-container ul li a:hover i {
  font-weight: 900;
  color: var(--text-hover-color);
}
.sidebar-container ul li a i {
  width: 30px;
  text-align: center;
  font-size: 1.2rem;
  position: relative;
}
.sidebar-container ul li a i .notification-badge {
  z-index: 999;
  position: absolute;
  top: -13px;
  right: 0;
  background-color: rgb(230, 57, 57);
  color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.541);
  font-size: 0.7rem;
  border-radius: 5px;
  padding: 0.2rem 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-container ul li a span {
  font-weight: 500;
  font-size: 17px;
  white-space: nowrap;
  /* Metnin taşmasını engellemek için eklenen özellik */
  overflow: hidden;
  /* Metnin taşmasını engellemek için eklenen özellik */
  text-overflow: ellipsis;
  /* Metnin taşmasını engellemek için eklenen özellik */
}
.sidebar-container ul li .small-sidebar-links {
  justify-content: center;
}
.sidebar-container ul li .active-link {
  color: var(--text-hover-color);
  font-weight: bold;
  fill: var(--text-hover-color);
}
.sidebar-container ul li .active-link span {
  color: var(--text-hover-color);
  font-weight: bold;
}
.sidebar-container .sidebar-logo {
  text-align: center;
  padding: 0.5rem;
  overflow: hidden;
}/*# sourceMappingURL=sidebar.css.map */
.tender-form-add-detail-form-container {
  border: 1px solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.tender-form-add-detail-form-container .custom-dates-container {
  margin: 0.5rem 0.3rem;
  padding: 0.5rem;
  box-shadow: 0 0 5px rgb(114, 114, 114);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0;
}
.tender-form-add-detail-form-container .custom-dates-container .form-item {
  margin: 0;
}
.tender-form-add-detail-form-container .custom-dates-container .delete-button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.tender-form-add-detail-form-container .other-date-quantity {
  margin-top: 1rem;
  color: var(--text-color);
  font-size: var(--text-font-size);
}
.tender-form-add-detail-form-container .other-date-quantity span {
  color: rgb(207, 110, 0);
}/*# sourceMappingURL=TenderFormAddDetailModal.css.map */
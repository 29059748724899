.documents-page-container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* document-box style */
.document-box-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  width: 120px;
  height: 120px;
  padding: 0.5rem;
  border: 5px solid rgba(0, 0, 0, 0.123);
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--select-background-color);
}
.document-box-container:hover {
  cursor: pointer;
  scale: 1.05;
  transition: scale 0.1s ease-in-out;
}
.document-box-container i {
  font-size: 2rem;
  color: var(--select-color);
}
.document-box-container .document-name {
  width: 100%;
  text-align: center;
}
.document-box-container .document-name p {
  color: var(--select-color);
  font-size: 0.9rem;
  font-weight: bold;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}/*# sourceMappingURL=documentStyle.css.map */
.inputradiotext-container {
  width: -moz-fit-content;
  width: fit-content;
}
.inputradiotext-container label {
  color: var(--text-color);
  font-size: 0.85rem;
}
.inputradiotext-container label:hover {
  cursor: pointer;
  color: var(--text-hover-color);
  font-weight: 700;
}
.inputradiotext-container input {
  display: none;
}
.inputradiotext-container .inputradiotext-active {
  color: var(--text-hover-color);
  font-weight: 800;
}/*# sourceMappingURL=inputRadioText.css.map */
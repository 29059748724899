@import '../../../../styles/variables';


.tender-detail-supplier-container {
    position: relative;
    width: 100%;
    height: var(--main-height);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    overflow: auto;

    @media (max-width:1028px) {
        padding-bottom: .1rem;
    }

    .opportunities-tender-detail {
        min-width: 400px;
        height: var(--main-height);

        @media (max-width:1028px) {
            width: 100%;
            min-width: 100%;
        }

    }

    .opportunities-offers-table {
        height: var(--main-height);

        flex: 1;
        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: 1rem;

        display: grid;
        grid-template-rows: 50px 1fr;
        grid-template-areas: "links" "tables";
        gap: 1rem;



        .tender-detail-tables-links {
            grid-area: links;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;

            overflow: auto;

            .table-link,
            .offer-edit-button {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                gap: .5rem;
            }

            .table-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .3rem;
                color: var(--text-color);
            }

            .offer-edit-button {

                button {
                    padding: .2rem .5rem;
                    width: max-content;

                    span {
                        font-size: .8rem;
                    }

                    &:hover {
                        // cursor: pointer;
                        font-weight: 600;
                        background-color: whitesmoke;
                    }

                }

                a {
                    text-decoration: none;
                }
            }
        }

        .tender-detail-table {
            grid-area: tables;
        }
    }

}
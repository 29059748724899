@import '../../styles/variables';

.tender-detail-box {
    width: 100%;
    height: 100%;

    border: var(--grey-border);
    border-radius: var(--button-radius);
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: .5rem;

    overflow: hidden;
    color: rgb(19, 148, 223);

    .tender-detail-top {
        height: 35px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;


        .tender-detail-top-left {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: .5rem;

            h4 {
                font-size: .9rem;
                color: var(--text-color);
            }
        }

        .tender-detail-top-right {

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            .tender-detail-top-edit-button {
                // padding: .3rem;

                span {
                    font-size: .8rem;
                }

            }

            a {
                text-decoration: none;
            }
        }
    }

    .tender-detail-main {
        flex: 1;
        width: 100%;

        display: flex;
        flex-direction: column;
        gap: .5rem;
        overflow: hidden;

        .tender-detail-image {
            height: 140px;
            width: 100%;

            @media (min-width:1290px) {
                height: 20vh;

            }
        }

        .tender-detail-info {
            flex: 1;
            height: 100px;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            // gap: .2rem;

            overflow: auto;

            .tender-detail-box-documan-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                .detail-text-title {
                    width: 30%;
                    padding-left: .3rem;
                    font-size: .7rem;
                    color: var(--text-color)
                }

                .documan-box {
                    width: 70%;
                    padding-left: 2rem;

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    .detail-text {
                        font-size: .7rem;
                        font-weight: 700;
                        text-decoration: none;
                        color: var(--text-color);
                        text-decoration: underline;
                    }

                }
            }

            .frequency-detail-box {
                border: 1px solid black;
                padding: .3rem;
                margin: .3rem;
                display: flex;
                flex-direction: column;
                gap: .5rem;
            }
        }
    }

}
@charset "UTF-8";
:root {
  --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
  --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
  --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
  --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-color-light: #535B6A;
  --text-color-dark: #C8C8C8;
  --text-hover-color-light: #344250;
  --text-hover-color-dark: #FBFBFB;
  --table-select-color-light: #FBFBFB;
  --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);
  --pagination-color-dark: #344250;
  --pagination-color-light: #FBFBFB;
  --button-background-color-light: #fbfbfbe4;
  --button-background-color-dark: #535b6abe;
  --button-background-hover-color-dark: #535B6A;
  --button-background-hover-color-light: #FBFBFB;
  --button-color-light: #FBFBFB;
  --button-color-dark: #535B6A;
  --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
  --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);
  --button-radius: 10px;
  --box-radius: 20px;
  --logo-radius: 10px;
  --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
  --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);
  --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;
  --select-background-color-dark: #535B6A;
  --select-background-color-light: #FBFBFB;
  --select-color-dark: #535B6A;
  --select-color-light: #FBFBFB;
  --scroll-color: #888888;
}

.light-theme-styles {
  --box-background-color: var(--box-background-color-light);
  --box-shadow: var(--box-shadow-light);
  --text-color: var(--text-color-light);
  --text-hover-color: var(--text-hover-color-light);
  --text-shadow: var(--text-shadow-light);
  --button-background-color: var(--button-background-color-light);
  --button-background-hover-color: var(--button-background-hover-color-light);
  --button-color: var(--button-color-dark);
  --button-box-shadow: var(var(--button-box-shadow-light));
  --table-select-color: var(--table-select-color-light);
  --input-shadow: var(--input-shadow-light);
  --select-background-color: var(--select-background-color-dark);
  --select-color: var(--select-color-light);
  --grey-border: var(--grey-border-dark);
}

.dark-theme-styles {
  --box-background-color: var(--box-background-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --text-color: var(--text-color-dark);
  --text-hover-color: var(--text-hover-color-dark);
  --text-shadow: var(--text-shadow-dark);
  --button-background-color: var(--button-background-color-dark);
  --button-background-hover-color: var(--button-background-hover-color-dark);
  --button-color: var(--button-color-light);
  --button-box-shadow: var(var(--button-box-shadow-dark));
  --table-select-color: var(--table-select-color-dark);
  --input-shadow: var(--input-shadow-dark);
  --select-background-color: var(--select-background-color-light);
  --select-color: var(--select-color-dark);
  --grey-border: var(--grey-border-light);
}

.table-null-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
}
@media (max-width: 1400px) {
  .table-null-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .table-null-text {
    font-size: 0.8rem;
  }
}

.disabled-box {
  pointer-events: none;
  opacity: 0.6;
}

/* Scroll stil */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.login-container {
  width: 100%;
  height: 100%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 500px;
  grid-template-rows: calc(100vh - 2rem);
  grid-template-areas: "main form";
  gap: 1rem;
  overflow: hidden;
}
@media screen and (max-width: 1028px) {
  .login-container {
    overflow: auto;
    grid-template-columns: 1fr;
    grid-template-rows: calc(100vh - 1rem) max-content;
    grid-template-areas: "main" "form";
  }
}

.login-main-box {
  background-image: url("/src/assets/images/login_image.png");
  background-repeat: no-repeat;
  background-size: cover;
  grid-area: main;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  padding: 3rem;
  overflow: auto;
  /* Animasyonlu buton */
}
@media screen and (max-width: 1028px) {
  .login-main-box {
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
  }
}
.login-main-box .login-button {
  /* Başlangıçta gizle */
  display: none;
  flex: 1;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s, transform 0.5s;
}
@media screen and (max-width: 1028px) {
  .login-main-box .login-button {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transform: translateY(0);
  }
  .login-main-box .login-button button {
    padding: 0.5rem;
    font-size: 1rem;
    border-radius: 10px;
    border: none;
    box-shadow: 0 0 10px black;
  }
}
.login-main-box h3 {
  margin-bottom: 2rem;
  color: whitesmoke;
  font-size: 1.5rem;
  font-weight: 300;
}
@media screen and (max-width: 1028px) {
  .login-main-box h3 {
    margin-bottom: 1rem;
  }
}
.login-main-box ul {
  padding: 1.5rem;
  color: whitesmoke;
}
.login-main-box ul li {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 300;
}
.login-main-box img {
  margin-bottom: 3rem;
}
@media screen and (max-width: 1028px) {
  .login-main-box img {
    width: 200px;
    margin-bottom: 1rem;
  }
}

.login-form-box {
  grid-area: form;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  margin: -1rem;
  padding: 1rem;
}
.login-form-box .login-form-inputs {
  flex: 1;
  background: var(--box-background-color);
  border-radius: var(--box-radius);
  box-shadow: var(--box-shadow);
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  overflow: auto;
}
.login-form-box .login-form-inputs h4 {
  font-size: 0.9rem;
  color: var(--text-color);
}
.login-form-box .login-form-inputs input {
  padding: 0.1rem 0.3rem;
  box-shadow: var(--input-shadow);
}
.login-form-box .login-form-inputs label,
.login-form-box .login-form-inputs input {
  color: var(--text-color);
  font-size: 0.7rem;
}
.login-form-box .login-form-inputs .login-box .login-form-login {
  padding: 0 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.2rem;
}
.login-form-box .login-form-inputs .login-box .login-form-login .inputs {
  min-height: 100px;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.3rem;
}
.login-form-box .login-form-inputs .login-box .login-form-login .login-buttons-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 0.3rem;
  /* Link komponenti */
}
.login-form-box .login-form-inputs .login-box .login-form-login .login-buttons-group button {
  padding: 0.2rem;
  font-size: 0.7rem;
}
.login-form-box .login-form-inputs .login-box .login-form-login .login-buttons-group a {
  width: 100%;
  text-decoration: none;
}
.login-form-box .login-form-inputs .info-box {
  padding: 0 1rem;
  font-size: 0.8rem;
}
.login-form-box .login-form-inputs .info-box p {
  line-height: 20px;
  margin: 0.45rem 0;
  color: var(--text-color);
}
.login-form-box .login-form-inputs .apply-box .login-form-apply {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs {
  border: var(--grey-border);
  border-radius: var(--button-radius);
  padding: 0.7rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 0.5rem;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs label {
  font-size: 0.8rem;
  color: var(--text-color);
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs input,
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs textarea {
  font-size: 0.7rem;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs input,
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs textarea {
  box-shadow: var(--input-shadow);
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs .role-select-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs .role-select-container label {
  color: var(--text-color);
  font-size: 0.7rem;
  width: -moz-max-content;
  width: max-content;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs .role-select-container select {
  cursor: pointer;
  background-color: var(--select-background-color);
  color: var(--select-color);
  border: none;
  outline: none;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  padding: 0.2rem 0.5rem;
  width: 75%;
  padding: 0.3rem;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs .descriptipon-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs .descriptipon-container label {
  color: var(--text-color);
  font-size: 0.8rem;
  width: -moz-max-content;
  width: max-content;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply .inputs .descriptipon-container textarea {
  width: 75%;
  height: 45px;
  padding: 0.3rem;
  border-radius: var(--button-radius);
  border: none;
  outline: none;
  box-shadow: var(--input-shadow);
  padding: 0.5rem;
}
.login-form-box .login-form-inputs .apply-box .login-form-apply button {
  padding: 0.3rem;
  font-size: 0.7rem;
}
.login-form-box .lang-box {
  height: 60px;
}/*# sourceMappingURL=login.css.map */
.error-box-container {
  border-radius: var(--button-radius);
  box-shadow: inset 0 0 15px red;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.2em;
  overflow: auto;
}
.error-box-container .error-title {
  margin-bottom: 16px;
}
.error-box-container .error-title h5 {
  font-size: 1em;
  color: #fa1616;
}
.error-box-container .error-title p {
  font-weight: bold;
  font-size: 1em;
  color: var(--text-color);
}
.error-box-container .error-message h5 {
  font-size: 1em;
  color: #fa1616;
}
.error-box-container .error-message code {
  font-weight: bold;
  font-size: 1em;
  color: var(--text-color);
}
@media screen and (max-width: 768px) {
  .error-box-container .error-title h5 {
    font-size: 1.5em;
  }
  .error-box-container .error-title p {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 480px) {
  .error-box-container .error-title h5 {
    font-size: 1.2em;
  }
  .error-box-container .error-title p {
    font-size: 1em;
  }
}/*# sourceMappingURL=errorComponent.css.map */
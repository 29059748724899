@import "../../styles/variables";

.user-edit-card-container {
    width: 100%;
    height: var(--main-height);

    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;

    @media screen and (max-width: 1028px) {
        height: max-content;
    }


    .info-edit-container {
        width: 100%;
        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: .6rem;
        overflow: auto;

        .top-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;

            .title-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                button{
                    padding: .5rem;
                }

                .title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .3rem;
                    color: var(--text-color);
                }
            }

            .button-group {

                button {
                    width: max-content;
                    padding: .5rem;
                    gap: .3rem;

                    i{
                        padding: 0;
                    }
                }
            }
        }

        .main-box {
            display: flex;
            flex-direction: column;
            gap: .3rem;

            .image-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: .3rem;

                .image-box {
                    width: 150px;
                    height: 140px;
                    overflow: hidden;
                    border-radius: var(--button-radius);
                    box-shadow: var(--box-shadow);
                    margin-bottom: .2rem;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .image-detail {
                        display: none;


                    }

                    &:hover .image-detail {
                        display: block;
                        position: fixed;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 9999;
                        width: 300px;
                        height: 300px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
                        border-radius: var(--button-radius);
                        transition: all 0.3s ease;

                    }
                }

                .image-buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;
                    margin-bottom: .2rem;

                    button {
                        flex: 1;
                        height: 0;
                        padding: .8rem;

                        font-size: .7rem;
                        position: relative;
                        overflow: hidden;

                        background-color: var(--select-background-color);
                        color: var(--select-color);

                        label,
                        span {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                cursor: pointer;
                            }

                        }
                    }
                }

            }

            .input-container {
                display: flex;
                flex-direction: column;
                gap: .3rem;

                input,
                label {
                    font-size: .7rem;
                }

                label {
                    color: var(--text-color);
                }

                input {
                    padding: .2rem .8rem;
                }

                .red-input {
                    label {
                        color: #CC4A4B;
                    }

                    input {
                        background-color: #CC4A4B;
                        color: white;

                        &::placeholder {
                            color: white;
                        }
                    }
                }
            }
        }
    }

    .change-pass-container {
        width: 100%;
        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: .6rem;
        overflow: auto;
        flex: 1;

        .top-box {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;
            margin-bottom: .5rem;

            .title-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                .title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .3rem;
                    color: var(--text-color);
                }
            }
        }

        .main-box {
            display: flex;
            flex-direction: column;
            gap: .3rem;

            input,
            label {
                font-size: .7rem;
            }

            label {
                color: var(--text-color);
            }

            input {
                padding: .2rem .8rem;
                outline: none;
            }

            button {
                height: 0;
                width: 100%;
                padding: .8rem;
                margin-top: .2rem;
                font-size: .7rem;

                background-color: var(--select-background-color);
                color: var(--select-color);

                span {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                }
            }
        }
    }

}
.delete-modal-container {
    // display: none;
    background: var(--box-background-color);
    height: 100%;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.delete-modal-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    color: var(--text-color);

    .delete-modal-button-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;
    }
}
.input-standart {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.input-standart label {
  font-size: 0.8rem;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.651);
}
.input-standart .input-price {
  position: relative;
  width: 75%;
}
.input-standart .input-price input {
  width: 100%;
  background-color: #FBFBFB;
  font-weight: 700;
  box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  border-radius: 10px;
  border: none;
  padding: 5px 10px;
  padding-left: 2rem;
  outline: none;
}
.input-standart .input-price span {
  position: absolute;
  left: 10px;
  top: 2px;
  width: 5%;
  height: 100%;
}
.input-standart .input-price .input-error {
  border: 2px solid red;
}/*# sourceMappingURL=inputPrice.css.map */
.white-button {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  border: none;
  border-radius: var(--button-radius);
  background-color: var(--button-background-color);
  color: var(--button-color);
  box-shadow: var(--box-shadow);
}
.white-button .white-button-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.white-button .white-button-text {
  width: -moz-max-content;
  width: max-content;
}
.white-button:hover {
  font-weight: 900;
}
.white-button:hover i {
  font-weight: 900;
  transform: scale(1.1, 1.1);
}

.white-button-cursor {
  cursor: pointer;
}

.white-button-disabled {
  cursor: no-drop;
  color: var(--button-color);
  background-color: var(--button-background-color);
  border-radius: var(--button-radius);
  opacity: 0.9;
}/*# sourceMappingURL=whiteButton.css.map */
@import '../../styles/variables';

.company-application-form {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: .5rem;

    .offerex-logo-container {
        height: 10vh;
        display: flex;
        align-items: center;

        img {
            width: 200px;
        }
    }

    .company-application-form-apply {
        background: var(--box-background-color);
        border-radius: var(--box-radius);
        box-shadow: var(--box-shadow);

        padding: 2rem;
        width: 500px;
        height: 70vh;
        overflow: auto;

        @media (max-width: 578px) {
            width: 95%;
            padding: 1rem;
        }

        h4 {
            margin-bottom: 1rem;
            color: var(--text-color);
        }

        .company-application-form-line {
            border: var(--grey-border);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: .5rem;
            padding: 1.5rem;
            margin-bottom: 1rem;


            @media (max-width: 578px) {
                padding: 1rem;
            }

            label {
                font-size: .8rem;
            }

            .input-login-container {
                width: 100%;

                label {
                    width: max-content;
                    white-space: nowrap; // Satır atlama yapmadan yazıları aynı satırda tutar

                    @media (max-width: 578px) {
                        font-size: .7rem;
                    }
                }

                .input-login {
                    width: 65%;

                    input {
                        width: 100%;
                        color: black;

                        @media (max-width: 578px) {
                            font-size: .7rem;
                        }
                    }
                }

                select {
                    @include button-light;

                    &:hover {
                        cursor: pointer;
                    }

                    width: 65%;
                    font-size: .8rem;
                }
            }

            textarea {
                width: 65%;

                background-color: #FBFBFB;
                color: black;
                font-weight: 700;
                box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
                border-radius: 10px;
                border: none;
                padding: 5px 10px;

                outline: none;
            }

        }
    }

    .lang-box {
        width: 500px;
        height: 7vh;


        @media (max-width: 578px) {
            width: 95%;

        }
    }

    .copyright-container {
        height: 3vh;

        p {
            font-size: .8rem;
            color: var(--text-color);
        }
    }
}
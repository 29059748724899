.label-input-info {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;

    label {
        min-width: 100px;
        font-weight: bold;
        color: var(--text-color);

        @media (max-width:1028px) {
            min-width: 70px;
        }

        @media (max-width:768px) {
            min-width: 60px;

        }
    }

    input {
        width: 45%;


        background-color: #FBFBFB;
        color: black;
        font-weight: 700;
        box-shadow: var(--input-shadow);
        border-radius: 10px;
        border: none;
        padding: 5px 10px;
        outline: none;

        @media (max-width:1028px) {
            flex: 1;
        }
    }

    .label-input-loading {
        position: absolute;
        left: 45%;
        font-weight: bold;
    }

    span {
        width: 45%;
        color: var(--text-color);

        flex-wrap: wrap;

        @media (max-width:1028px) {
            display: none;
        }
    }

    .label-input-info-disabled {
        opacity: .5;
    }

    .error-input {
        border: 2px solid red;
    }
}
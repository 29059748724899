@import '../../../../styles/variables';

.profile-edit-container {
    width: 100%;

    height: var(--main-height);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    overflow: auto;


    @media (max-width:1028px) {
        padding-bottom: .1rem;
    }


    .edit-box {
        min-width: 400px;

        @media screen and (max-width: 1028px) {
            width: 100%;
            min-width: 100%;
            height: max-content;
        }
    }

    .right-container {
        flex: 1;
        // height: 100vh;
        opacity: .5;
        pointer-events: none;

        border: var(--grey-border);
        border-radius: 10px;
        display: grid;
        grid-template-rows: 35px 1fr;
        grid-template-areas:
            "page-link"
            "company-table"
        ;
        gap: 1rem;
        padding: .8rem;

        @media screen and (max-width: 1028px) {
            width: 100%;
            height: var(--main-height);
        }

        .page-link {
            grid-area: page-link;
            width: 100%;

            display: flex;
            flex-direction: row;
            gap: .3rem;
            flex-wrap: nowrap;

            button {
                padding: .2rem .5rem;
                border-radius: .5rem;
                border: none;
                color: var(--button-color);


                &:hover {
                    cursor: pointer;
                    color: var(--text-hover-color);
                    font-weight: 600;
                }
            }

            .link-active {
                background-color: white;
                box-shadow: var(--box-shadow);
                background-color: var(--button-background-color);
                color: var(--text-hover-color);
                font-weight: 600;
            }

            .link-not-active {
                background: transparent;

            }
        }


        .table {
            grid-area: company-table;
            width: 100%;
        }
    }

}
@import '../../../styles/variables';

.forgot-password-container {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: .5rem;

    .offerex-logo-container {
        height: 10vh;
        display: flex;
        align-items: center;

        img {
            width: 200px;
        }
    }


    .forgot-password-box {
        background: var(--box-background-color);
        border-radius: var(--box-radius);
        box-shadow: var(--box-shadow);

        width: 500px;
        height: 70vh;
        text-align: center;
        padding: .5rem;
        overflow: auto;

        @media (max-width: 578px) {
            width: 95%;
            padding: 1rem;
        }

        .forgot-password-main {

            height: 100%;
            overflow: auto;
            padding: 1rem;

            h4,
            p {
                width: 100%;
                text-align: left;
                margin-bottom: .7rem;
                color: var(--text-color);
            }

            .form-line {
                border: var(--grey-border);
                border-radius: 10px;
                padding: 1rem;
                margin-bottom: 1rem;

                .input-standart {
                    margin-bottom: .8rem;

                    label {
                        color: var(--text-color);
                    }

                    input {
                        outline: none;
                        font-size: .8rem;
                    }
                }
            }

            .forgot-password-buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 1rem;

                font-size: .8rem;

                /* Link komponent */
                a {
                    text-decoration: none;
                    width: 100%;
                }

            }
        }
    }


    .lang-box {
        width: 500px;
        height: 7vh;

        @media (max-width: 578px) {
            width: 95%;

        }
    }

    .copyright-container {
        height: 3vh;

        p {
            font-size: .8rem;
            color: var(--text-color);
        }
    }
}
.login-lang-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: .5rem;

    background: var(--box-background-color);
    border-radius: var(--box-radius);
    box-shadow: var(--box-shadow);

    overflow: auto;

    .lang-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        img {
            width: 20px;
            height: 20px;
            object-fit: cover;
            border-radius: 50%;
        }

        .text {
            font-size: .8rem;
            color: var(--text-color);
        }

        &:hover {
            cursor: pointer;
            opacity: 1;

            img {
                opacity: 1;
            }

            .text {
                font-weight: bold;
            }

        }

    }

    .active-lang {
        opacity: 1;
        font-weight: bold;

        img {
            opacity: 1;
        }
    }

    .disabled-lang {
        opacity: 0.5;

        img {
            opacity: 0.5;
        }
    }

}
.documents-page-container {
    width: 100%;
    height: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;


}

/* document-box style */
.document-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: .3rem;

    width: 120px;
    height: 120px;
    padding: .5rem;
    border: 5px solid rgba(0, 0, 0, 0.123);
    border-radius: var(--button-radius);
    box-shadow: var(--box-shadow);
    background-color: var(--select-background-color);


    &:hover {
        cursor: pointer;
        scale: 1.05;
        transition: scale .1s ease-in-out;
    }

    i {
        font-size: 2rem;
        color: var(--select-color);
    }

    .document-name {
        width: 100%;
        text-align: center;

        p {
            color: var(--select-color);

            font-size: .9rem;
            font-weight: bold;

            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
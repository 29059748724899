@import "../../../styles/variables";

.history-offers-modal-container {
    height: 60vh;

    .ant-modal-close {
        /* close ikonu için stil kuralları */
        z-index: 2; /* İstediğiniz bir değer belirleyin */
      }

    thead {
        position: sticky;
        top: -10px;
        background-color: rgb(255, 255, 255);
    }

    .button-group {
        display: none;
    }
}
@import "../../styles/variables";

.notification-box-container {
    border-radius: var(--button-radius);
    box-shadow: var(--box-shadow);
    width: 100%;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 10px black;
    }

    .notification-title-box {
        border-top-left-radius: var(--button-radius);
        border-top-right-radius: var(--button-radius);

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        padding: .5rem 1rem;
        background-color: var(--button-background-color);
        color: var(--button-color);

        &:hover {
            cursor: pointer;

            .title {
                // text-decoration: underline;
                color: rgb(71, 177, 166);
            }

        }

        .title-box-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            .fa-envelope {
                color: turquoise
            }

            .title {
                font-size: .8rem;
            }
        }

        .create-date {
            font-size: .6rem;
        }

    }

    .notification-body-box {

        &:hover {
            cursor: pointer;

            .text {
                font-weight: bold;
                color: rgb(71, 177, 166);
            }
        }

        .text {
            padding: .7rem;
            font-size: .8rem;
            color: var(--text-color);
        }
    }
}
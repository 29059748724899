.custom-select {
  width: 100%;
}
.custom-select select {
  width: 100%;
  cursor: pointer;
  background-color: var(--button-background-color);
  color: var(--button-color);
  border: none;
  outline: none;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  padding: 0.2rem 0.5rem;
}/*# sourceMappingURL=customSelect.css.map */
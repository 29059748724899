@import "../../../styles/variables";

.maincompanies-container {
    height: var(--main-height);
    display: grid;
    grid-template-rows: 60px 40px calc(var(--main-height) - 100px);
    grid-template-areas:
        "maincompanies-title"
        "maincompanies-link"
        "maincompanies-table"
    ;
    overflow: auto;

    @media (max-width:768px) {
        grid-template-rows: 100px 40px calc(var(--main-height) - 140px);

    }

    .maincompanies-edit-box {
        // border: 1px solid black;
        grid-area: maincompanies-title;
        width: 100%;
    }

    .maincompanies-page-link {
        // border: 1px solid black;
        grid-area: maincompanies-link;
        width: 100%;
        padding-left: .5rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .maincompanies-table {
        // border: 1px solid red;
        grid-area: maincompanies-table;
        width: 100%;

    }

}
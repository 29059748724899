/* Sayfalama */
.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.pagination button {
  width: 2rem;
  padding: 0.3rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #FFFFFF;
  border-radius: 10px;
  font-weight: bold;
  box-shadow: var(--box-shadow);
}
.pagination button:hover {
  cursor: pointer;
  background-color: var(--text-hover-color);
}

.pagination-active-button {
  background-color: #344250;
  color: white;
}

.pagination button:hover:not(.pagination-active-button) {
  background-color: #FBFBFB;
  color: #344250;
}/*# sourceMappingURL=pagination.css.map */
@charset "UTF-8";
:root {
  --main-height: calc(100vh - 80px - 30px - 1rem - 20px - 2rem - .5rem);
  --box-background-color-light: linear-gradient(359.84deg, #E2E4EA 6.56%, #EAECF1 97.86%);
  --box-background-color-dark: linear-gradient(158.53deg, #363E46 -25.92%, #2C343C 82.89%);
  --box-shadow-light: -1px -1px 7px -1px rgb(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --box-shadow-dark: -2px -2px 7px -1px rgb(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-shadow-light: -1px -1px 7px -1px rgba(255, 255, 255, 0.5), 7px 7px 7px -7px rgba(166, 180, 200, 0.5);
  --text-shadow-dark: -2px -2px 7px -1px rgba(72, 83, 92, 0.35), 7px 7px 7px -6px rgba(35, 40, 45, 0.7);
  --text-color-light: #535B6A;
  --text-color-dark: #C8C8C8;
  --text-hover-color-light: #344250;
  --text-hover-color-dark: #FBFBFB;
  --table-select-color-light: #FBFBFB;
  --table-select-color-dark: linear-gradient(158.53deg, #2F363D -25.92%, #222931 82.89%);
  --pagination-color-dark: #344250;
  --pagination-color-light: #FBFBFB;
  --button-background-color-light: #fbfbfbe4;
  --button-background-color-dark: #535b6abe;
  --button-background-hover-color-dark: #535B6A;
  --button-background-hover-color-light: #FBFBFB;
  --button-color-light: #FBFBFB;
  --button-color-dark: #535B6A;
  --button-box-shadow-light: 5px -5px 5px rgba(255, 255, 255, 0.5), 5px 5px 5px -6px rgba(166, 180, 200, 0.5);
  --button-box-shadow-dark: -5px -5px 5px rgba(72, 83, 92, 0.35), 5px 5px 5px -6px rgba(35, 40, 45, 0.7);
  --button-radius: 10px;
  --box-radius: 20px;
  --logo-radius: 10px;
  --grey-border-dark: 1px solid rgba(153, 153, 153, 0.7);
  --grey-border-light: 1px solid rgba(228, 228, 228, 0.205);
  --input-shadow-light: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
  --input-shadow-dark: inset -2px -2px 2px #3F4653, inset 2px 2px 4px #1F1F1F;
  --select-background-color-dark: #535B6A;
  --select-background-color-light: #FBFBFB;
  --select-color-dark: #535B6A;
  --select-color-light: #FBFBFB;
  --scroll-color: #888888;
}

.light-theme-styles {
  --box-background-color: var(--box-background-color-light);
  --box-shadow: var(--box-shadow-light);
  --text-color: var(--text-color-light);
  --text-hover-color: var(--text-hover-color-light);
  --text-shadow: var(--text-shadow-light);
  --button-background-color: var(--button-background-color-light);
  --button-background-hover-color: var(--button-background-hover-color-light);
  --button-color: var(--button-color-dark);
  --button-box-shadow: var(var(--button-box-shadow-light));
  --table-select-color: var(--table-select-color-light);
  --input-shadow: var(--input-shadow-light);
  --select-background-color: var(--select-background-color-dark);
  --select-color: var(--select-color-light);
  --grey-border: var(--grey-border-dark);
}

.dark-theme-styles {
  --box-background-color: var(--box-background-color-dark);
  --box-shadow: var(--box-shadow-dark);
  --text-color: var(--text-color-dark);
  --text-hover-color: var(--text-hover-color-dark);
  --text-shadow: var(--text-shadow-dark);
  --button-background-color: var(--button-background-color-dark);
  --button-background-hover-color: var(--button-background-hover-color-dark);
  --button-color: var(--button-color-light);
  --button-box-shadow: var(var(--button-box-shadow-dark));
  --table-select-color: var(--table-select-color-dark);
  --input-shadow: var(--input-shadow-dark);
  --select-background-color: var(--select-background-color-light);
  --select-color: var(--select-color-dark);
  --grey-border: var(--grey-border-light);
}

.table-null-text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  font-size: 1rem;
}
@media (max-width: 1400px) {
  .table-null-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 768px) {
  .table-null-text {
    font-size: 0.8rem;
  }
}

.disabled-box {
  pointer-events: none;
  opacity: 0.6;
}

/* Scroll stil */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.navbar-container {
  width: 100%;
  height: 100%;
  background: var(--box-background-color);
  box-shadow: var(--box-shadow);
  border-radius: var(--box-radius);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 1rem;
}
.navbar-container .navbar-logo {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  /* MenuIcon */
}
.navbar-container .navbar-logo h2 {
  padding-top: 0.5rem;
}
.navbar-container .navbar-logo svg {
  fill: var(--text-color);
  stroke: var(--text-color);
  filter: drop-shadow(var(--box-shadow));
}
.navbar-container .navbar-logo svg:hover {
  cursor: pointer;
}
.navbar-container .navbar-logo img:hover {
  cursor: pointer;
  opacity: 0.9;
}
.navbar-container .navbar-right-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.navbar-container .navbar-right-box .navbar-profile {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box {
  position: relative;
  width: 100%;
  min-width: 200px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  /* PROFİL DROPDOWN MENÜ AYARLARI */
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box {
    min-width: -moz-max-content;
    min-width: max-content;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px 10px 0 0;
  padding: 0.2rem 0.5rem;
  background-color: #535B6A;
  color: rgb(248, 248, 248);
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .profile {
    border-radius: 50%;
    height: -moz-max-content;
    height: max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 0.2rem;
    padding: 0 0;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile .profile-image {
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  border-radius: 10px;
  overflow: hidden;
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .profile .profile-image {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 0;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile .profile-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile .profile-image img:hover {
  fill: black;
  stroke: black;
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .profile .profile-name {
    display: none;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .dropdown-none-profile {
  border-radius: 10px;
  transition: border-radius 0.5s ease-in-out;
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .dropdown-none-profile {
    border-radius: 50%;
    height: -moz-max-content;
    height: max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 0.2rem;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .dropdown-active-profile {
  background-color: #344250;
  transition: background-color 0.5s ease-in-out;
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .dropdown-active-profile {
    background-color: #535B6A;
    border-radius: 50% 50% 0 0;
    height: -moz-max-content;
    height: max-content;
    width: -moz-max-content;
    width: max-content;
    padding: 0.2rem;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box {
  background-color: #535B6A;
  color: white;
  position: absolute;
  top: 35px;
  right: 0;
  width: 100%;
  min-width: 200px;
  height: auto;
  border-radius: 0 0 10px 10px;
  z-index: 2;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box {
    top: 40px;
    right: -0.05rem;
    border-radius: 10px 0 10px 10px;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul {
  list-style-type: none;
  border-radius: 0 0 10px 10px;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul li {
  padding: 0.6rem;
  text-align: center;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul li:hover {
  background-color: #344250;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-last-li {
  border-radius: 0 0 10px 10px;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul a {
  text-decoration: none;
  color: inherit;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .theme-li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .theme-li span {
  font-size: 0.6rem;
  color: rgba(227, 227, 227, 0.663);
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .theme-li .theme-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .theme-li .theme-buttons button {
  background: transparent;
  border: none;
  background-color: rgb(237, 237, 237);
  color: rgba(0, 0, 0, 0.403);
  padding: 0 1rem;
  border-radius: var(--button-radius);
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .theme-li .theme-buttons button:hover {
  color: rgb(0, 0, 0);
  box-shadow: 0 0 5px rgb(14, 14, 14);
  cursor: pointer;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .theme-li .theme-buttons .select-theme {
  box-shadow: 0 0 5px turquoise;
  color: rgb(0, 0, 0);
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.2rem;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li span {
  font-size: 0.7rem;
  color: rgba(227, 227, 227, 0.663);
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li .language-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li .language-container .lang-button-img {
  width: 25px;
  height: 25px;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li .language-container .lang-button-img:hover {
  color: rgb(0, 0, 0);
  filter: drop-shadow(0 0 5px rgb(255, 255, 255));
  cursor: pointer;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li .language-container .active-lang-img {
  filter: drop-shadow(0 0 6px rgb(37, 37, 37));
  color: rgb(0, 0, 0);
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box ul .dropdown-lang-li .language-container .disabled-lang-img {
  opacity: 0.5;
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box-open {
  max-height: -moz-max-content;
  max-height: max-content;
}
@media (max-width: 768px) {
  .navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box-open {
    margin-right: 0.05rem;
  }
}
.navbar-container .navbar-right-box .navbar-profile .profile-box .profile-dropdown-box-close {
  background-color: #535B6A;
}/*# sourceMappingURL=navbar.css.map */
.banner-company-container {
  height: var(--main-height);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.banner-company-container .banner-company-edit-box {
  height: 60px;
}
.banner-company-container .banner-company-main {
  flex: 1;
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  padding: 1rem;
}
.banner-company-container .banner-company-main .banner-card {
  display: flex;
  flex-direction: column;
  height: -moz-max-content;
  height: max-content;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  background-color: white;
  overflow: hidden;
}
.banner-company-container .banner-company-main .banner-card .image-box {
  height: 170px;
  overflow: hidden;
}
.banner-company-container .banner-company-main .banner-card .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner-company-container .banner-company-main .banner-card .detail-box {
  padding: 1rem;
  padding-bottom: 0.3rem;
}
.banner-company-container .banner-company-main .banner-card .detail-box h4 {
  margin-bottom: 0.5rem;
}
.banner-company-container .banner-company-main .banner-card .detail-box .banner-status {
  float: right;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.banner-company-container .banner-company-main .banner-card .detail-box .banner-status-orange {
  background-color: orange;
  color: #fff;
}
.banner-company-container .banner-company-main .banner-card .detail-box .banner-status-green {
  background-color: green;
  color: #fff;
}
.banner-company-container .banner-company-main .banner-card .detail-box .banner-status-red {
  background-color: red;
  color: #fff;
}
.banner-company-container .banner-company-main .banner-card .detail-box small {
  color: #777;
}
.banner-company-container .banner-company-main .banner-card .edit-box {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.banner-company-container .banner-company-main .banner-card .edit-box .status-buttons {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.banner-company-container .banner-company-pagination {
  height: 40px;
  display: flex;
  align-items: center;
}

.confirm-modal-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.confirm-modal-container .modal-actions-button {
  align-self: flex-end;
  display: flex;
  gap: 0.5rem;
}/*# sourceMappingURL=bannersCompany.css.map */
@import "../../../../styles/variables";

.navbar-search {
    width: 180px;
    position: relative;

    /* SearchIcon */
    svg {
        position: absolute;
        top: -12px;
        left: -10px;
    }

    input {
        // background: transparent url('../../../../assets/icons/search.svg') no-repeat -10px center;
        @include input-box;
        width: 180px;

        height: 36px;
        padding: .4rem .4rem .4rem 2.5rem;
        border: none;
        outline: none;
    }

}
.banner-edit-form-container {
  max-width: 400px;
  margin: 0 auto;
}
.banner-edit-form-container .form-group {
  margin-bottom: 15px;
}
.banner-edit-form-container .form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.banner-edit-form-container .form-group .change-image-label {
  background-color: rgb(0, 119, 255);
  color: white;
  width: -moz-max-content;
  width: max-content;
  font-size: 0.8rem;
  font-weight: normal;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}
.banner-edit-form-container .form-group input[type=file] {
  display: none;
}
.banner-edit-form-container .form-group .image-box {
  position: relative;
  width: 150px;
  width: 100%;
  height: 160px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}
.banner-edit-form-container .form-group .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.banner-edit-form-container input[type=number],
.banner-edit-form-container input[type=text] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.banner-edit-form-container .date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.banner-edit-form-container .date-container .form-group {
  width: 50%;
}
.banner-edit-form-container .date-container .form-group input {
  width: 100%;
}
.banner-edit-form-container .form-buttons {
  display: flex;
  justify-content: space-between;
}
.banner-edit-form-container .form-buttons button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.banner-edit-form-container .form-buttons button[type=submit] {
  background-color: #007bff;
  color: #fff;
}
.banner-edit-form-container .form-buttons button[type=button] {
  background-color: #ccc;
  color: #000;
}
.banner-edit-form-container .example-banner-box {
  border: 2px solid gray;
  width: 100%;
  height: 30vh;
  padding: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}
.banner-edit-form-container .example-banner-box .banners {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.1rem;
}
.banner-edit-form-container .example-banner-box .banners .small-banner,
.banner-edit-form-container .example-banner-box .banners .medium-banner,
.banner-edit-form-container .example-banner-box .banners .big-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  color: white;
}
.banner-edit-form-container .example-banner-box .banners .small-banner {
  width: 25%;
  height: 25%;
}
.banner-edit-form-container .example-banner-box .banners .medium-banner {
  width: 25%;
  height: 50%;
}
.banner-edit-form-container .example-banner-box .banners .big-banner {
  width: 40%;
  height: 50%;
}/*# sourceMappingURL=bannerEditForm.css.map */
@import "../../../../styles/variables";

// Ana sayfa stil ve tasarımı
.support-request-container {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .support-edit-box {
        height: 60px;

        @media (max-width:768px) {
            height: max-content;
            
        }
    }

    .support-request-main {
        flex: 1;
        overflow: auto;

        // Talep kutusu bileşeni stil ve tasarımı
        .ticket-box {
            margin-bottom: 24px;
            padding: 16px;
            background-color: #f0f0f0;
            border-radius: 4px;
            border-radius: var(--button-radius);

            display: flex;
            flex-direction: column;
            gap: .5rem;

            .date {
                float: right;
            }

            .ticket-status {
                font-weight: bold;
            }

            .admin-reply {
                margin-top: 16px;
                background-color: #fff;
                padding: 16px;
                border-radius: 4px;
                border-radius: var(--button-radius);


                h4 {
                    margin-bottom: 8px;
                }

                p {
                    margin-bottom: 16px;
                }
            }
        }

        .photo-modal-container {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.397);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1020;

            .photo-box {
                width: 80%;
                height: 80%;
                border-radius: 10px;
                overflow: hidden;
                border: 5px solid black;
                background-color: black;
                position: relative;

                .ticket-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .close-button {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    font-size: .9rem;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:hover {
                        cursor: pointer;
                        opacity: .7;
                    }
                }
            }

        }
    }

    .pagination {
        height: 40px;
    }
}
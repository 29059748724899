.add-recommed-supplier-table-container {
    width: 100%;
    height: 100%;
    // border: var(--grey-border);
    // border-radius: var(--button-radius);
    overflow: auto;
    position: relative;
    
    .table-box {
        // padding: 0 1rem;
        width: 100%;
        height: calc(100% - 50px);
        overflow: auto;

        position: absolute;
        top: 0;
        left: 0;


        table {
            width: 100%;
            border-collapse: collapse;
            overflow: auto;

            th,
            td {
                text-align: center;
                font-size: .9rem;
                padding: .5rem .8rem;

                white-space: nowrap;
                /* Metnin otomatik olarak satıra sığdırılmasını engeller */
                max-width: 150px;
                overflow: hidden;
                /* Hücre sınırlarının dışına taşan metnin görünmemesini sağlar */
                text-overflow: ellipsis;
                /* Hücre sınırları içinde sığmayan metnin "..." ile gösterilmesini sağlar */
                // height: 50px;

                .image-box {
                    width: 50px;
                    height: 50px;
                    border-radius: var(--logo-radius);
                    box-shadow: var(--box-shadow);
                    overflow: hidden;

                    img {
                        // filter: drop-shadow(10px 15px 12px rgba(166, 180, 200, 0.9))
                        width: 100%;
                        height: 100%;
                        // object-fit: cover;
                        object-fit: contain;
                        background-color: rgb(214, 214, 214);
                    }
                }


            }

            thead {
                width: 100%;
                // position: relative;

                tr {
                    // background-color: #eaebf1;
                    background: var(--box-background-color);
                    color: var(--text-hover-color);
                    // box-shadow: $box-shadow;
                    border-radius: .3rem;
                    position: sticky;
                    top: 0;
                    // z-index: 1;
                    height: 50px;


                }
            }

            tbody {
                tr {
                    color: var(--text-color);
                    // max-height: 50px;
                    // background-color: red;


                    &:hover {
                        // cursor: pointer;
                        // background-color: #FBFBFB;
                        // box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.25), 10px 10px 12px -6px rgba(166, 180, 200, 0.25);
                        // border-radius: 10px;
                        // background: var(--table-select-color);
                        // box-shadow: var(--box-shadow);
                        // border-radius: var(--box-radius);
                    }

                    &:hover td:first-child {
                        border-radius: 10px 0 0 10px;
                    }

                    &:hover td:last-child {
                        border-radius: 0 10px 10px 0;
                    }

                }
            }

        }

        /* Tablodaki elemanı seçerken oluşan animasyonlar */
        // .table-row-select {
        //     background: var(--table-select-color);
        //     box-shadow: var(--box-shadow);
        //     border-radius: var(--button-radius);

        //     td {
        //         color: var(--text-hover-color);
        //         font-weight: 700;

        //         &:first-child {
        //             border-radius: 10px 0 0 10px;
        //         }

        //         &:last-child {
        //             border-radius: 0 10px 10px 0;
        //         }
        //     }

        // }

        .table-row-select td:first-child {
            border-radius: 10px 0 0 10px;
        }

        .table-row-select td:last-child {
            border-radius: 0 10px 10px 0;
        }

    }


    /* Pagination tablodaki grid düzen ayarı */
    .pagination {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 50px;
        // padding: 0 1rem;
    }

}
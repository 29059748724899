.first-time-offerex-modal-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 450px;
    overflow: auto;
    padding: .5rem;

    .title {
        // margin-bottom: 1rem;
        text-align: center;
    }

    p {
        font-size: 1.1rem;
    }
}
.button-dark-style {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color:  #535B6A;
    color: white;
    box-shadow: 10px 10px 12px -6px rgba(0, 0, 0, 0.1);
    border-radius: .5rem;
    border: none;
    padding: .5rem;
    font-weight: 700;
    position: relative;
   
    i{
        font-size:1.2rem ;
    }

    span{
        padding-left: .3rem;
    }

}

.button-dark-active{

    &:hover {
        cursor: pointer;
        background-color: #344250;
        color: white;
    } 
}

.button-dark-disabled{
    opacity: .5;
    cursor: no-drop;
}
@import '../../../styles/variables';

.register-container {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: .5rem;

    .offerex-logo-container {
        height: 6vh;
        display: flex;
        align-items: center;

        img {
            width: 200px;
        }
    }


    .register-box {
        background: var(--box-background-color);
        border-radius: var(--box-radius);
        box-shadow: var(--box-shadow);

        width: 500px;
        height: 74vh;

        padding: 1rem;
        overflow: auto;

        @media (max-width: 578px) {
            width: 95%;
            padding: 1rem;
        }


        h5 {
            width: 100%;
            text-align: center;
            margin-bottom: .7rem;
            color: var(--text-color);
        }

        .form-line {
            border: var(--grey-border);
            border-radius: $border-radius;
            padding: 1rem;
            margin-bottom: .7rem;

            .img-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 1rem;

                img {
                    border-radius: var(--button-radius);
                    width: 100px;
                    height: 100px;
                    margin-bottom: .6rem;
                }

                .img-buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    button {
                        padding: .3rem;
                        font-size: .8rem;
                        position: relative;
                        height: 30px;

                        label {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: .5rem;
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;


                            &:hover {
                                cursor: pointer;
                                color: var(--text-hover-color);
                            }
                        }
                    }

                }
            }

            .inputs-container {
                display: flex;
                flex-direction: column;
                gap: .5rem;

                label {
                    color: var(--text-color);
                }

                label,
                input {
                    font-size: .8rem;
                }

                input {
                    padding: .2rem .7rem;
                }

                .agreement-buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: row;

                    .policy-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: .5rem;

                        .policy-box {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            gap: .5rem;

                            .policy-text {
                                span {
                                    font-size: .8rem;
                                    color: var(--text-color);

                                }

                                .policy-link {

                                    text-decoration: underline;

                                    i {
                                        margin-right: .3rem;
                                    }

                                    &:hover {
                                        cursor: pointer;
                                        font-weight: bold;
                                        color: rgb(0, 174, 255);
                                    }
                                }
                            }

                            .input-box {
                                width: max-content;
                                background-color: white;
                                border-radius: .3rem;
                                box-shadow: var(--box-shadow);
                                padding: 0 .4rem;

                                &:hover {
                                    cursor: pointer;
                                    box-shadow: 0 0 5px rgb(34, 116, 209);
                                }

                                i {
                                    font-size: .8rem;
                                }
                            }
                        }

                        .policy-opacity {
                            opacity: 0.5;
                        }
                    }

                }
            }
        }

        .button-apply {
            padding: .3rem;
        }
    }

    .lang-box {
        width: 500px;
        height: 7vh;

        @media (max-width: 578px) {
            width: 95%;

        }
    }

    .copyright-container {
        height: 3vh;

        p {
            font-size: .8rem;
            color: var(--text-color);
        }
    }
}


/* modal */
.capture-modal {
    position: 'fixed';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding-top: 5rem;
    background-color: rgba(0, 0, 0, 0.71);
}

.capture-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .capture-modal-box {

        // border: 5px solid $text-color-dark;
        overflow: hidden;
        border-radius: 2rem;
        box-shadow: $box-shadow;
        box-shadow: -5px -5px 12px rgba(63, 63, 63, 0.773), 10px 10px 12px -6px rgba(63, 63, 63, 0.773);

        .capture-modal-webcam {
            width: 500px;

        }

        .modal-button-groups {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .2rem;
            width: 100%;

            button {
                // width: 70px;
                // background-color: rgb(196, 195, 195);
                width: 100%;
                border-radius: 0;


                &:hover {
                    cursor: pointer;
                    color: whitesmoke;
                    background-color: $text-color-light;
                }
            }
        }
    }
}
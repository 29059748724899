.banner-edit-form-container {
    max-width: 400px;
    margin: 0 auto;

    .form-group {
        margin-bottom: 15px;

        label {
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
        }

        .change-image-label {
            background-color: rgb(0, 119, 255);
            color: white;
            width: max-content;
            font-size: .8rem;
            font-weight: normal;
            padding: .5rem;
            border-radius: 5px;
            cursor: pointer;
        }

        input[type='file'] {
            display: none;
        }

        .image-box {
            position: relative;
            width: 150px;
            width: 100%;
            height: 160px;
            border: 1px solid #ccc;
            overflow: hidden;
            border-radius: 5px;
            margin-bottom: .5rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    input[type='number'],
    input[type='text'] {
        width: 100%;
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }

    .date-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        .form-group {
            width: 50%;

            input {
                width: 100%;
            }
        }
    }

    .form-buttons {
        display: flex;
        justify-content: space-between;

        button {
            padding: 10px 15px;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }

        button[type='submit'] {
            background-color: #007bff;
            color: #fff;
        }

        button[type='button'] {
            background-color: #ccc;
            color: #000;
        }
    }

    .example-banner-box {
        border: 2px solid gray;
        width: 100%;
        height: 30vh;
        padding: 1rem;
        margin-bottom: .5rem;

        display: flex;
        flex-direction: column;

        .banners {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: .1rem;


            .small-banner,
            .medium-banner,
            .big-banner {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: red;
                color: white;
            }

            .small-banner {
                width: 25%;
                height: 25%;
            }

            .medium-banner {
                width: 25%;
                height: 50%;
            }

            .big-banner {
                width: 40%;
                height: 50%;
            }
        }

    }
}
@import '../../../../styles/variables';

/* USER-ADD */
.user-add-container {
    height: 100%;
    display: grid;
    grid-template-rows: 50px calc(var(--main-height) - 50px - .5rem);
    grid-template-areas:
        "edit-box"
        "add-form"
    ;
    gap: .5rem;


    .user-edit-box {
        grid-area: edit-box;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .user-edit-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            h4,
            i {
                color: var(--text-color);
            }
        }

        .user-edit-button-groups {
            padding-right: 1rem;
        }

    }

    form {
        grid-area: add-form;

        border: var(--grey-border);
        border-radius: 10px;
        padding: 1rem;

        hr {
            margin: 1rem 0;
        }

        .select-container {
            max-width: 400px;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 1rem;

            label {
                @include form-label;
                color: var(--text-color);
            }

            select {
                width: 75%;
                @include select-box;
            }
        }

        .input-standart {
            max-width: 400px;

            label {
                color: var(--text-color);
            }

            input {
                outline: none;
            }
        }
    }

}
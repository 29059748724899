@import "../../styles/variables";

.admin-support-request-container {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .support-edit-box {
        height: 60px;
    }

    .admin-support-main {
        flex: 1;
        overflow: auto;

        h4 {
            width: 100%;

        }

        .date {
            float: right;
        }
    }

    .pagination {
        height: 40px;
    }
}
@import '../../../../styles/variables';
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.offer-detail-container {
    position: relative;
    width: 100%;
    height: var(--main-height);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    overflow: auto;

    @media (max-width:1028px) {
        width: 100%;
    }

    .tender-detail {
        min-width: 400px;
        height: var(--main-height);

        @media (max-width:1028px) {
            width: 100%;
            min-width: 100%;
        }
    }

    .offer-detail {
        height: var(--main-height);
        flex: 1;

        @media (max-width:1028px) {
            padding-bottom: .5rem;
        }
    }

}
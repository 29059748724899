@import "../../styles/variables";

.app {
    width: 100%;
    height: 100vh;
    overflow: hidden;

}

.bg-light-image {
    @include background-image('/src/assets/images/light-background.png');
}

.bg-dark-image {
    @include background-image('/src/assets/images/dark-background.png');
}

.two-detail-containers {
    height: var(--main-height);
    display: flex;
    flex-direction: row;
    gap: 1rem;

    .first-detail-box {}

    .second-detail-box {}
}
@import '../../../styles/variables';

/* USERS*/
.users-container {
    height: var(--main-height);
    display: grid;
    grid-template-rows: 60px calc(var(--main-height) - 60px - .5rem);
    grid-template-areas:
        "edit-box"
        "company-table"
    ;
    gap: .5rem;
    overflow: auto;

    @media (max-width:768px) {
        grid-template-rows: 100px calc(var(--main-height) - 100px - .5rem);
    }

    .users-edit-box {
        // border: 1px solid black;
        grid-area: edit-box;
        width: 100%;
    }

    .users-table {
        // border: 1px solid red;
        grid-area: company-table;
        width: 100%;
    }

}
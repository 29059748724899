@import '../../../styles/variables';


.select-filter-sort {
    @include button-light;
    background-color: var(--select-background-color);
    color: var(--select-color);

    &:hover {
        cursor: pointer;
        // color: rgba(0, 0, 0, 0.932)
    }

    width: max-content;
}

.select-filter-roles {
    width: 100px;
    @include button-light;
    background-color: var(--select-background-color);
    color: var(--select-color);

    &:hover {
        cursor: pointer;
        // color: rgba(0, 0, 0, 0.932)
    }
}
.input-standart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .3rem;

    label {
        font-size: .8rem;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.651);
    }

    input {
        width: 75%;

        background-color: #FBFBFB;
        color: #535B6A;
        color: black;
        font-weight: 700;
        box-shadow: inset -2px -2px 2px rgba(255, 255, 255, 0.5), inset 2px 2px 4px rgba(83, 91, 106, 0.5);
        border-radius: 10px;
        border: none;
        padding: 5px 10px;

        outline-color: #535B6A;
    }

    .input-error {
        border: 2px solid red;
    }
}
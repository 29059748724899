@import '../../../styles/variables';

/* fotoğraf detay modal */
.modal-carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.568);
  z-index: 10000000010;

  display: flex;
  justify-content: center;
  align-items: center;


  .images-detail-main {
    position: relative;

    width: 80%;
    height: 90vh;

    &.out-modal {
      animation: unfoldOut 1s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    &.open-modal {
      animation: unfoldIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }


    .images-detail-close {
      position: absolute;
      top: 10px;
      right: 10px;
      color: white;
      font-size: 24px;
      cursor: pointer;

      background-color: #344250;
      border: none;
      padding: .3rem 1rem;
      border-radius: 10px;

      i {
        font-size: 2rem;
        color: white;
      }

      &:hover {
        cursor: pointer;
        background-color: #526170;
      }
    }

    .modal-image-box {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: black;


      .modal-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .images-detail-button-next,
    .images-detail-button-prev {
      position: absolute;
      width: max-content;
      bottom: 10px;
      font-size: 1rem;
      background-color: #344250;
      border: none;
      padding: .3rem 1rem;
      border-radius: 10px;

      i {
        font-size: 2rem;
        color: white;
      }

      &:hover {
        cursor: pointer;
        background-color: #526170;
      }
    }

    .images-detail-button-next {
      right: 10px;
    }

    .images-detail-button-prev {
      left: 10px;

    }
  }
}

@keyframes unfoldIn {
  0% {
    // transform: scaleY(0.01) scaleX(0);
    width: 0px;
    height: 0px;
  }

  100% {
    // transform: scaleY(1) scaleX(1);
    width: 80%;
    height: 90vh;
  }
}

@keyframes unfoldOut {
  0% {
    // transform: scaleY(1) scaleX(1);
    width: 80%;
    height: 90vh;
  }

  100% {
    // transform: scaleY(0.01) scaleX(0);
    width: 0px;
    height: 0px;
  }
}
@import '../../styles/variables';

.image-detail-card {
    width: 100%;
    height: 120px;

    height: 100%;

    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;


    .image-detail-img-box {
        width: 100%;
        height: 100%;
        overflow: hidden;

        .image-detail-img-card {
            position: relative;
            display: inline-block;
            width: 100%;
            height: 100%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                // object-fit: cover;
                object-fit: contain;
                background-color: rgb(214, 214, 214);
            }

            .image-detail-card-img {
                width: 100%;
                height: 100%;
                // object-fit: cover;
                object-fit: contain;
                background-color: rgb(214, 214, 214);
            }
        }

    }

    button {
        background-color: #535B6A;
        color: #fff;
        position: absolute;
        bottom: 10px;
        right: 10px;

        &:hover {
            cursor: pointer;
            background-color: #535b6ad5;
        }
    }
}
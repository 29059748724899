.modal-container {
    display: none;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);


    &.open {
        display: block;
    }

    .modal {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;


        .modal-content {
            background-color: #fefefe;
            margin: 0 auto;
            padding: 25px;
            border: 1px solid #888;
            width: 80%;
            max-width: 650px;
            max-height: 100vh;
            position: relative;
        }

        .close-button {
            position: absolute;
            top: 0px;
            right: 5px;
            font-size: 24px;
            font-weight: bold;
            cursor: pointer;
        }

        .pdf-iframe {
            width: 100%;
            height: 500px;
            border: none;
        }
    }

}
.inputradiotext-container {
    width: fit-content;

    label {
        color: var(--text-color);
        font-size: .85rem;

        &:hover {
            cursor: pointer;
            color: var(--text-hover-color);
            font-weight: 700;
        }

    }

    input {
        display: none;
    }

    .inputradiotext-active {
        color: var(--text-hover-color);
        font-weight: 800;
    }
}
.company-detail-card-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.company-detail-card-container .company-detail-card-top-box {
  height: 90px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem;
  background-color: white;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.company-detail-card-container .company-detail-card-top-box .image-box {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: rgb(195, 195, 195);
  border-radius: var(--button-radius);
  min-width: calc(90px - 2rem);
  max-width: 120px;
}
.company-detail-card-container .company-detail-card-top-box .image-box img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: var(--button-radius);
  box-shadow: 0 0 5px rgb(63, 63, 63);
  background-color: rgb(156, 156, 156);
}
.company-detail-card-container .company-detail-card-top-box .image-box .detail-image-box {
  display: none;
}
.company-detail-card-container .company-detail-card-top-box .image-box:hover .detail-image-box {
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  height: 400px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: var(--button-radius);
  transition: all 0.3s ease;
}
.company-detail-card-container .company-detail-card-top-box .image-box:hover .detail-image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
  background-color: rgb(156, 156, 156);
}
.company-detail-card-container .company-detail-card-top-box .user-detail-name-role {
  color: black;
}
.company-detail-card-container .company-detail-card-bottom-box {
  flex: 1;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  padding: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
}/*# sourceMappingURL=companyDetailCard.css.map */
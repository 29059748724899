@import '../../../styles/variables';

.revised-modal-container {
    background: var(--box-background-color);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.revised-modal-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-items: flex-start;
    gap: 1rem;

    .revised-input-container {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        color: var(--text-color);

        label {
            width: max-content;
            color: var(--text-color);
            font-size: .9rem
        }

        input {
            @include input-box-light;
            padding: .3rem .5rem;
            flex: 1;
        }
    }

    .revised-modal-button-group {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        button {
            padding: .2rem .5rem;
            border-radius: .5rem;
            border: none;

            span {
                width: max-content;
            }
        }

    }
}
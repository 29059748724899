.homepage-container {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    overflow: auto;
    padding-bottom: .1rem;

    // .homepage-charts {
    //     display: grid;
    //     grid-template-columns: repeat(12, 1fr);        
    //     grid-auto-rows: calc(20vh - 1rem);
    //     grid-auto-flow: dense;
    //     gap: 0.5rem;


    //     .big-banner {
    //         grid-column: span 6;
    //         grid-row: span 2;

    //     }

    //     .medium-banner {
    //         grid-column: span 3;
    //         grid-row: span 2;
    //     }

    //     .small-banner,
    //     .exchangerate-card {
    //         grid-column: span 3;
    //         grid-row: span 1;

    //     }

    //     @media screen and (max-width: 768px) {

    //         .big-banner,
    //         .exchangerate-card,
    //         .medium-banner,
    //         .small-banner {
    //             grid-column: span 12;
    //         }
    //     }

    //     .card {
    //         width: 45%;
    //         height: calc(20vh);
    //         width: 100%;
    //         height: 100%;
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //     }
    // }

    .homepage-detail-cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: .5rem;

        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 0.5rem;

        .card {
            height: calc(20vh - .5rem);
        }

    }

    .homepage-tables {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        grid-auto-rows: auto;
        grid-gap: 0.5rem;

        @media (min-width:1700px) {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }

        @media (min-width:1700px) {
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        }

        .table {
            height: calc(var(--main-height) - 40vh - .5rem);


            @media (max-width:400px) {
                width: 300px;
            }
        }
    }

}
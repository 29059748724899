@import '../../../styles/variables';

.confirm-modal-container {
    background: var(--box-background-color);

    height: 100%;
    width: 100%;
}

.confirm-modal-main {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;

    background: var(--box-background-color);

    .confirm-text-container {
        height: 100%;
        display: flex;
        align-items: center;

        p {
            font-size: .9rem;
            color: var(--text-color);
        }
    }

    .confirm-modal-button-group {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        button {
            padding: .2rem .5rem;
            border-radius: .5rem;
            border: none;

            span {
                width: max-content;
            }
        }

    }
}
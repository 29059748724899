@import '../../../styles/variables';

.edit-container {
    width: 100%;
    height: 100%;

    overflow-x: auto;
    position: relative;
    overflow: auto;

    .edit-box {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (max-width:768px) {
            flex-direction: column;
            gap: .5rem;
            overflow: hidden;
        }

        /* router-link (Link) stil */
        a {
            text-decoration: none;
        }

        .edit-container-title {
            width: max-content;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .3rem;

            @media (max-width:768px) {
                width: 100%;
            }

            .title {
                width: max-content;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .3rem;
                color: var(--text-color);
            }
        }

        .edit-container-button-group {
            width: max-content;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            gap: .5rem;

            padding: 0 1rem;
            text-align: center;
            overflow: auto;

            @media (max-width:768px) {
                width: 100%;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;

                overflow: auto;
                padding: 1rem;
            }
        }

    }
}



.button-group-left,
.button-group-right,
.button-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: .5rem;
    text-align: center;

    /* butonun içindeki yazıların satır atlamasını engellemek için */
    span {
        width: max-content;
    }

    i {
        padding: 0 .5rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}



/* search input */
.search-container {
    overflow: hidden;
    transition: width 0.3s ease-in-out;
    animation: expand-search 0.3s forwards;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .3rem;

    padding: .05rem;

    input {
        border: none;
        outline: none;
        box-shadow: var(--input-shadow);
        border-radius: 5px;
        padding: .1rem .5rem;
    }
}



@keyframes expand-search {
    0% {
        width: 0;
    }

    100% {
        width: 200px;
    }
}

select {
    @include select-box;
    padding: .5rem;
    font-weight: bold;
}

.select-roles {
    width: 100px;
    background-color: var(--select-background-color);
    color: var(--select-color);
}

.select-filter-sort {
    width: 120px;
    background-color: var(--select-background-color);
    color: var(--select-color);
}
/* Sayfalama */
.pagination {
    // border: 2px solid red;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;
    // padding: 1rem;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.pagination button {

    width: 2rem;
    // height: 33px;
    padding: .3rem;

    text-align: center;
    text-decoration: none;
    transition: background-color .3s;

    border: 1px solid #FFFFFF;
    // box-shadow: -10px -10px 12px rgba(255, 255, 255, 0.25), 10px 10px 12px -6px rgba(166, 180, 200, 0.25);
    border-radius: 10px;
    font-weight: bold;
    // background: var(--text-color);
    // color: var(--bacground-color);
    // border-color: var(--text-hover-color);
    box-shadow: var(--box-shadow);
    // border-radius: var(--button-radius);

    &:hover {
        cursor: pointer;
        background-color: var(--text-hover-color);
    }
}

.pagination-active-button {
    background-color: #344250;
    color: white;

    // background-color: var(--text-hover-color);
    // color: var(--bacground-color);
}

.pagination button:hover:not(.pagination-active-button) {
    background-color: #FBFBFB;
    color: #344250;
}
@import "../../../styles/variables";

.exchange-card-container {
    height: 100%;
    border: var(--grey-border);
    border-radius: var(--button-radius);
    padding: .5rem;
    overflow: auto;

    display: flex;


    .money-table {
        width: 100%;
        height: 100%;
        table-layout: fixed;


        thead {
            tr {
                th {
                    @include title-font-size;
                    color: var(--text-color);
                    white-space: nowrap;
                    /* Hücre içeriğinin satır sonuna sığmamasını sağlar */
                    overflow: hidden;
                    /* Hücre içeriğinin taşması durumunda gizler */
                    text-overflow: ellipsis;
                    /* Hücre içeriğinin taşması durumunda kırpılmış metin gösterir */

                    &:first-child {
                        min-width: max-content;
                        text-align: left;
                    }

                    &:nth-child(2) {
                        text-align: center;
                    }

                    text-align: left;
                }
            }
        }

        tbody {
            tr {
                td {
                    // @include text-font-size;
                    color: var(--text-color);
                    font-size: .9rem;
                    white-space: nowrap;
                    /* Hücre içeriğinin satır sonuna sığmamasını sağlar */
                    overflow: hidden;
                    /* Hücre içeriğinin taşması durumunda gizler */
                    text-overflow: ellipsis;
                    /* Hücre içeriğinin taşması durumunda kırpılmış metin gösterir */

                    &:nth-child(2) {
                        color: rgb(28, 202, 28);
                        text-align: center;
                    }

                    text-align: left;
                }
            }
        }
    }

    // .exchange-card-main {
    //     // border: 1px solid red;
    //     width: 100%;
    //     height: 100%;
    //     overflow: auto;

    //     display: flex;
    //     flex-direction: column;
    //     // flex-wrap: wrap;
    //     // align-items: center;
    //     align-content: center;
    //     justify-content: space-around;
    //     gap: 1rem;


    //     .money-box {
    //         // border: 1px solid black;
    //         // width: calc(50% - 1rem);
    //         // height: calc(50% - .5rem);
    //         display: flex;
    //         flex-direction: row;
    //         align-items: center;
    //         justify-content: center;
    //         gap: 1rem;

    //         .money-icon{
    //             color: rgb(46, 192, 46);
    //             font-size: 1.2rem;
    //         }

    //         .money{
    //             color: var(--text-color);
    //             font-size: 1.3rem;
    //         }
    //     }
    // }
}
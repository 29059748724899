@import '../../styles/variables';

.detail-text-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    .detail-text-title {
        width: 30%;
        padding-left: .3rem;
        font-size: .7rem;
        color: var(--text-color);
    }

    .detail-text {
        width: 70%;
        padding-left: 2rem;
        font-size: .7rem;
        font-weight: 700;
        color: var(--text-color);

    }
}
@import "../../../styles/variables";


.des-close-btn {
    border: none;
    background: none;
    float: right;

    i {
        font-size: 2rem;
        cursor: pointer;
        color: red;
    }
}

.des-title {
    margin-bottom: 1rem;
}

.des-note {
    flex-wrap: wrap;
    background-color: rgb(235, 235, 235);
    padding: .5rem;

}
.tender-form-add-detail-form-container {
    border: 1px solid black;
    border-radius: .5rem;
    padding: .5rem;


    .custom-dates-container {
        // border-radius: .5rem;
        margin: .5rem .3rem;
        padding: .5rem;
        box-shadow: 0 0 5px rgb(114, 114, 114);
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 0;

        .form-item {
            margin: 0;
        }

        .delete-button {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
        }

    }

    .other-date-quantity {
        margin-top: 1rem;
        color: var(--text-color);
        font-size: var(--text-font-size);
        span{
            color: rgb(207, 110, 0);
        }
    }
}
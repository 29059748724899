@charset "UTF-8";
.company-edit-container {
  width: 100%;
  height: var(--main-height);
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-areas: "company-info company-address";
  grid-template-rows: var(--main-height);
  gap: 1rem;
  overflow: auto;
}
@media screen and (max-width: 1028px) {
  .company-edit-container {
    grid-template-columns: 1fr;
    grid-template-areas: "company-info" "company-address";
  }
}
.company-edit-container .disabled-container {
  opacity: 0.5;
  pointer-events: none;
}
.company-edit-container .company-info-container {
  border: var(--grey-border);
  border-radius: var(--button-radius);
  grid-area: company-info;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
  overflow: auto;
}
.company-edit-container .company-info-container .info-top-container,
.company-edit-container .company-info-container .img-container,
.company-edit-container .company-info-container .inputs-container {
  padding: 0 1rem;
}
.company-edit-container .company-info-container .info-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.company-edit-container .company-info-container .info-top-container .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.company-edit-container .company-info-container form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.company-edit-container .company-info-container form .img-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.company-edit-container .company-info-container form .img-container .img-box {
  width: 100px;
  height: 100px;
  box-shadow: var(--box-shadow);
  border-radius: var(--button-radius);
  overflow: hidden;
}
.company-edit-container .company-info-container form .img-container .img-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.company-edit-container .company-info-container form .img-container .img-buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.company-edit-container .company-info-container form .img-container .img-buttons button {
  width: 100%;
  font-size: 0.7rem;
  height: 0;
  padding: 0.9rem;
  position: relative;
  background-color: var(--select-background-color);
  color: var(--select-color);
  /* Butonun pozisyonunu relative olarak ayarlayın */
}
.company-edit-container .company-info-container form .img-container .img-buttons button label {
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* Labelin pozisyonunu absolute olarak ayarlayın */
  top: 0;
  left: 0;
}
.company-edit-container .company-info-container form .inputs-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.5rem;
  overflow: auto;
}
.company-edit-container .company-info-container form .inputs-container label,
.company-edit-container .company-info-container form .inputs-container input {
  font-size: 0.7rem;
}
.company-edit-container .company-info-container form .inputs-container label {
  width: 110px;
  color: var(--text-color);
  font-weight: bold;
}
.company-edit-container .company-info-container form .inputs-container input {
  flex: 1;
}
.company-edit-container .company-info-container form .inputs-container .label-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
}
.company-edit-container .company-info-container form .inputs-container .label-select .select-box {
  flex: 1;
}
.company-edit-container .company-info-container form .inputs-container .label-select .select-box select {
  width: 100%;
  cursor: pointer;
  background-color: var(--select-background-color);
  color: var(--select-color);
  border: none;
  outline: none;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  padding: 0.2rem 0.5rem;
}
.company-edit-container .company-address-container {
  grid-area: company-address;
  border: var(--grey-border);
  border-radius: var(--button-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0;
}
.company-edit-container .company-address-container .address-top-container,
.company-edit-container .company-address-container form {
  padding: 0 1rem;
}
.company-edit-container .company-address-container form {
  padding-top: 1rem;
}
.company-edit-container .company-address-container .address-top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.company-edit-container .company-address-container .address-top-container .title-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  color: var(--text-color);
}
.company-edit-container .company-address-container form {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  overflow: auto;
}
.company-edit-container .company-address-container form label,
.company-edit-container .company-address-container form input {
  font-size: 0.7rem;
}
.company-edit-container .company-address-container form label {
  width: 100px;
  color: var(--text-color);
  font-weight: bold;
}
.company-edit-container .company-address-container form input {
  flex: 1;
}
.company-edit-container .company-address-container form .label-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.3rem;
}
.company-edit-container .company-address-container form .label-select .select-box {
  flex: 1;
}
.company-edit-container .company-address-container form .label-select .select-box select {
  width: 100%;
  cursor: pointer;
  background-color: var(--select-background-color);
  color: var(--select-color);
  border: none;
  outline: none;
  border-radius: var(--button-radius);
  box-shadow: var(--box-shadow);
  padding: 0.2rem 0.5rem;
}/*# sourceMappingURL=companyEdit.css.map */
.button-dark-style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #535B6A;
  color: white;
  box-shadow: 10px 10px 12px -6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem;
  font-weight: 700;
  position: relative;
}
.button-dark-style i {
  font-size: 1.2rem;
}
.button-dark-style span {
  padding-left: 0.3rem;
}

.button-dark-active:hover {
  cursor: pointer;
  background-color: #344250;
  color: white;
}

.button-dark-disabled {
  opacity: 0.5;
  cursor: no-drop;
}/*# sourceMappingURL=buttonDark.css.map */
@import "../../styles/variables";

.tutorial-card-container {
    flex-grow: 1;

    min-width: 220px;
    max-width: 250px;
    height: 200px;
    border: 2px solid rgb(219, 218, 218);
    border-radius: var(--button-radius);
    box-shadow: var(--box-shadow);
    overflow: hidden;

    &:hover .video-container {
        cursor: pointer;
        scale: 1.01;
        transition: scale .3s ease-in-out;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.61);

        i {
            opacity: 1;
        }
    }

    .video-container {
        position: relative;

        width: 100%;
        height: 120px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2rem;
            color: rgb(255, 255, 255);
            background-color: red;
            border: 5px solid rgb(255, 255, 255);
            border-radius: 50%;
            padding: .5rem .8rem;
            padding-right: .6rem;
            box-shadow: 0 0 10px black;
            opacity: .7;
        }

    }

    .info-container {
        width: 100%;
        height: 80px;
        background-color: whitesmoke;
        overflow: hidden;

        position: relative;

        p {
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            bottom: .5rem;
            padding: .5rem;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /* İstediğiniz satır sayısı */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: .9rem;
        }

    }


}
@import "../../styles/variables";

.login-container {
    width: 100%;
    height: 100%;
    padding: 1rem;

    display: grid;
    grid-template-columns: 1fr 500px;
    grid-template-rows: calc(100vh - 2rem);
    grid-template-areas:
        "main form"
    ;
    gap: 1rem;

    overflow: hidden;

    @media screen and (max-width: 1028px) {
        // display: flex;
        // flex-direction: column-reverse;
        overflow: auto;
        grid-template-columns: 1fr;
        grid-template-rows: calc(100vh - 1rem) max-content;
        grid-template-areas:
            "main"
            "form"
        ;
        // gap: .5rem;
        // padding: .3rem;
    }

}

.login-main-box {
    @include background-image("/src/assets/images/login_image.png");

    grid-area: main;
    border-radius: 10px;
    box-shadow: var(--box-shadow);

    padding: 3rem;
    overflow: auto;

    @media screen and (max-width: 1028px) {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;
    }

    /* Animasyonlu buton */
    .login-button {
        /* Başlangıçta gizle */
        display: none;
        flex: 1;
        opacity: 0;
        transform: translateY(20px);
        transition: opacity 0.5s, transform 0.5s;


        @media screen and (max-width: 1028px) {
            display: flex;
            justify-content: center;
            align-items: center;

            opacity: 1;
            transform: translateY(0);

            button {
                padding: .5rem;
                font-size: 1rem;
                border-radius: 10px;
                border: none;
                box-shadow: 0 0 10px black;
            }


        }
    }


    h3 {
        margin-bottom: 2rem;
        color: whitesmoke;
        font-size: 1.5rem;
        font-weight: 300;

        @media screen and (max-width: 1028px) {
            margin-bottom: 1rem;
        }
    }

    ul {
        padding: 1.5rem;
        color: whitesmoke;

        li {
            margin-bottom: 1rem;
            font-size: 1rem;
            font-weight: 300;
        }
    }

    img {
        margin-bottom: 3rem;

        @media screen and (max-width: 1028px) {
            width: 200px;
            margin-bottom: 1rem;
        }
    }
}

.login-form-box {
    grid-area: form;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    overflow: auto;
    margin: -1rem;
    padding: 1rem;

    .login-form-inputs {
        flex: 1;

        background: var(--box-background-color);
        border-radius: var(--box-radius);
        box-shadow: var(--box-shadow);

        padding: .5rem 0;

        display: flex;
        flex-direction: column;
        gap: .3rem;

        overflow: auto;

        h4 {
            font-size: .9rem;
            color: var(--text-color);
        }

        input {
            padding: .1rem .3rem;
            box-shadow: var(--input-shadow);
        }

        label,
        input {
            color: var(--text-color);
            font-size: .7rem;
        }

        .login-box {

            .login-form-login {
                padding: 0 1rem;
                width: 100%;
                height: 100%;

                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: .2rem;

                .inputs {
                    min-height: 100px;
                    border: var(--grey-border);
                    border-radius: var(--button-radius);
                    padding: .7rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    gap: .3rem;
                }

                .login-buttons-group {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    gap: 1rem;
                    padding-top: .3rem;

                    button {
                        padding: .2rem;
                        font-size: .7rem;
                    }

                    /* Link komponenti */
                    a {
                        width: 100%;
                        text-decoration: none;
                    }
                }
            }

        }

        .info-box {
            padding: 0 1rem;
            font-size: .8rem;

            p {
                line-height: 20px;
                margin: .45rem 0;
                color: var(--text-color);
            }

        }

        .apply-box {

            .login-form-apply {
                width: 100%;
                height: 100%;
                padding: 0 1rem;
                padding-bottom: .5rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: .5rem;

                .inputs {
                    border: var(--grey-border);
                    border-radius: var(--button-radius);
                    padding: .7rem 1rem;

                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    gap: .5rem;

                    label {
                        font-size: .8rem;
                        color: var(--text-color);
                    }

                    input,
                    textarea {

                        font-size: .7rem;
                    }

                    input,
                    textarea {
                        box-shadow: var(--input-shadow);
                    }

                    .role-select-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        label {
                            color: var(--text-color);
                            font-size: .7rem;
                            width: max-content;
                        }

                        select {
                            @include select-box;

                            width: 75%;
                            padding: .3rem;

                        }
                    }

                    .descriptipon-container {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        label {
                            color: var(--text-color);
                            font-size: .8rem;
                            width: max-content;
                        }


                        textarea {
                            width: 75%;
                            height: 45px;
                            padding: .3rem;
                            border-radius: var(--button-radius);
                            border: none;
                            outline: none;
                            box-shadow: var(--input-shadow);
                            padding: .5rem;
                        }
                    }
                }

                button {
                    padding: .3rem;
                    font-size: .7rem;
                }
            }
        }

    }

    .lang-box {
        height: 60px;
    }
}
@import '../../../styles/variables';

.suppliers-container {
    height: var(--main-height);
    display: grid;
    grid-template-rows: 60px 40px calc(var(--main-height) - 100px);
    grid-template-areas:
        "supplier-title"
        "supplier-link"
        "supplier-table"
    ;
    overflow: auto;

    @media (max-width:768px) {
        grid-template-rows: 150px 40px calc(var(--main-height) - 190px);

    }

    .suppliers-edit-box {
        // border: 2px solid black;
        grid-area: supplier-title;
    }

    .supplier-page-link {
        // border: 2px solid blue;
        grid-area: supplier-link;
        padding-left: .5rem;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }

    .suppliers-table {
        // border: 2px solid purple;
        grid-area: supplier-table;
    }

}
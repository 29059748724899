.tutorial-modal-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.tutorial-modal-container .title {
  text-align: center;
}
.tutorial-modal-container p {
  font-size: 1.1rem;
}
.tutorial-modal-container p .ask-icon,
.tutorial-modal-container p .howtowork-text {
  font-size: 1.3rem;
  font-weight: bold;
}/*# sourceMappingURL=tutorialModal.css.map */
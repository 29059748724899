@import '../../../../styles/variables';

.tender-detail-container {
    position: relative;
    width: 100%;
    height: var(--main-height);

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    overflow: auto;

    @media (max-width:1028px) {
        width: 100%;
        padding-bottom: .5rem;
    }


    .tender-detail-left-box {
        min-width: 400px;
        height: var(--main-height);

        @media (max-width:1028px) {
            width: 100%;
            min-width: 100%;
        }
    }

    .tender-detail-tables {
        height: var(--main-height);

        flex: 1;

        border: var(--grey-border);
        border-radius: var(--button-radius);
        padding: 1rem;

        display: flex;
        flex-direction: column;
        gap: .5rem;

        .tender-detail-tables-links {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;

            overflow: auto;

            .table-link,
            .offer-edit-button {
                width: 50%;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;
                gap: .5rem;

                padding: .5rem 1rem;
            }

            .table-link {

                button {
                    padding: .2rem .5rem;
                    border-radius: .5rem;
                    border: none;
                    color: var(--text-color);

                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .3rem;


                    &:hover {
                        cursor: pointer;
                        font-weight: 600;
                        color: var(--text-hover-color);
                    }

                    .supp-count {
                        background-color: rgb(13, 170, 231);
                        color: white;
                        padding: .1rem .5rem;
                        border-radius: 10px;
                        font-weight: bold;
                        font-size: .7rem;
                    }
                }

                .link-active {
                    background-color: var(--button-background-color);
                    color: var(--text-hover-color);
                    box-shadow: var(--box-shadow);
                    font-weight: 600;
                }

                .link-not-active {
                    background: transparent;

                }
            }

            .offer-edit-button {
                justify-content: flex-end;

                button {
                    padding: .2rem .5rem;
                    width: max-content;

                    span {
                        font-size: .8rem;
                    }

                    &:hover {
                        cursor: pointer;
                        font-weight: 600;
                    }
                }
            }



            .button-white-active {
                cursor: pointer;
            }

            .button-white-disabled {
                opacity: .5;
                cursor: no-drop;
            }

        }

        .tender-detail-table {
            flex: 1;
        }
    }


}
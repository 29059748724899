.supplyprocess-suppliercomp-card {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  margin: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.supplyprocess-suppliercomp-card .supplyprocess-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .supplyprocess-suppliercomp-card .supplyprocess-header {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.supplyprocess-suppliercomp-card .supplyprocess-header .deadline {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.supplyprocess-suppliercomp-card .supplyprocess-header .deadline p {
  color: #666;
}
.supplyprocess-suppliercomp-card .product-name {
  font-weight: bold;
}
.supplyprocess-suppliercomp-card .supplyprocess-company {
  color: #555;
}
.supplyprocess-suppliercomp-card .supplyprocess-phone {
  color: #555;
}
.supplyprocess-suppliercomp-card .supplyprocess-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.supplyprocess-suppliercomp-card .supplyprocess-bottom .status-badge {
  margin-top: 10px;
  min-width: -moz-max-content;
  min-width: max-content;
}
.supplyprocess-suppliercomp-card .supplyprocess-bottom .status-badge .badge {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
  font-weight: bold;
  color: #f5f5f5;
}
.supplyprocess-suppliercomp-card .supplyprocess-bottom .supplyprocess-button-groups {
  overflow: hidden;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.5rem;
}
@media (max-width: 768px) {
  .supplyprocess-suppliercomp-card .supplyprocess-bottom .supplyprocess-button-groups {
    justify-content: flex-start;
  }
}/*# sourceMappingURL=SupplyProcessCardSupplierComp.css.map */
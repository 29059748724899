@import "../../styles/variables";


.sidebar-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    overflow: hidden;

    // @include light-box;
    background: var(--box-background-color);
    box-shadow: var(--box-shadow);
    border-radius: var(--box-radius);
    padding-top: .5rem;

    ul {
        list-style-type: none;
        padding: 1rem;
        padding-top: 0;
        overflow: auto;

        li {
            margin: 1rem 0;

            &:hover {
                cursor: pointer;
            }


            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: nowrap;
                gap: .3rem;
                text-decoration: none;
                color: var(--text-color);

                &:hover span,
                &:hover i {
                    font-weight: 900;
                    color: var(--text-hover-color);
                }

                /* Icons */
                i {
                    // border: 1px solid black;
                    width: 30px;
                    text-align: center;
                    font-size: 1.2rem;
                    position: relative;

                    .notification-badge {
                        z-index: 999;
                        position: absolute;
                        top: -13px;
                        right: 0;
                        background-color: rgb(230, 57, 57);
                        color: white;
                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.541);
                        font-size: .7rem;
                        border-radius: 5px;
                        padding: .2rem .3rem;

                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }

                span {
                    font-weight: 500;
                    font-size: 17px;

                    white-space: nowrap;
                    /* Metnin taşmasını engellemek için eklenen özellik */
                    overflow: hidden;
                    /* Metnin taşmasını engellemek için eklenen özellik */
                    text-overflow: ellipsis;
                    /* Metnin taşmasını engellemek için eklenen özellik */
                }
            }

            .small-sidebar-links {
                justify-content: center;
            }

            .active-link {
                color: var(--text-hover-color);
                font-weight: bold;

                fill: var(--text-hover-color);

                span {
                    color: var(--text-hover-color);
                    font-weight: bold;
                }
            }



        }

        .notification-li {
            // position: relative;

            // .notification-badge {
            //     position: absolute;
            //     top: -11px;
            //     left: -13px;
            //     background-color: rgb(230, 57, 57);
            //     color: white;
            //     box-shadow: 0 0 5px rgba(0, 0, 0, 0.541);
            //     font-size: .7rem;
            //     border-radius: 5px;
            //     padding: .1rem .3rem;
            //     display: flex;
            //     justify-content: center;
            //     align-items: center;
            // }
        }
    }

    .sidebar-logo {
        // background-color: red;
        text-align: center;
        padding: .5rem;
        overflow: hidden;
    }

}
@import "../../../../styles/variables";

.how-it-work-container {
    height: var(--main-height);

    display: grid;
    grid-template-rows: 40px 40px calc(var(--main-height) - 80px);
    grid-template-areas:
        "howitwork-title-container"
        "howitwork-links"
        "howitwork-main-container"
    ;
    overflow: hidden;

    @media (max-width:768px) {
        grid-template-rows: 100px 40px calc(var(--main-height) - 140px);
    }


    .howitwork-title-container {
        grid-area: howitwork-title-container;
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 1rem;

        padding: .5rem;

        @media (max-width:768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: .5rem;
        }

        .title-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .3rem;
            color: var(--text-color);




            i {
                font-size: 1.3rem;
            }
        }

        .right-box {
            padding-right: 1rem;
        }
    }

    .howitwork-links {
        grid-area: howitwork-links;
        padding-left: .5rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-wrap: wrap;
    }

    .howitwork-main-container {
        grid-area: howitwork-main-container;
        // width: 100%;
        border: var(--grey-border);
        border-radius: var(--button-radius);

        overflow: auto;
    }
}
@import '../../../../styles/variables';

/* React select css */
.basic-multi-select {
    font-size: 14px;
    width: 75%;

    .select__control {
        border-radius: 10px;
        border-color: #ccc;
        background-color: #535B6A;
        box-shadow: none;
        outline: none;
        transition: border-color 0.2s ease-in-out;

        &:hover,
        &:focus {
            border-color: #999;
            cursor: pointer;
        }

        &--is-focused {
            border-color: #999;
            box-shadow: none;
        }

        .select__input input[type="text"] {
            color: #fff;
            /* Yazı rengi beyaz olarak ayarlandı */
        }

        .select__placeholder {
            color: #fff;
            /* Placeholder rengi beyaz olarak ayarlandı */
        }

    }


    .select__menu {

        background-color: #535B6A;
        font-size: 14px;

        z-index: 2;

        overflow: auto;


        .select__option {
            color: #f8f8f8;
            color: #333;
            cursor: pointer;
            font-weight: 400;
            padding: 8px 12px;

            &--is-selected {
                background-color: #e6e6e6;
                color: #333;
            }

            &:hover,
            &:focus {
                background-color: #ddd;
                color: #ffffff;
                color: #333;
            }


        }
    }
}

/* adet unit-type input */
.input-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;


    .input-standart {
        width: 100%;

        .input-select-box {
            width: 75%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }
}

.no-supplier {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1028px) {
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .no-supplier-text {
        color: var(--text-color);
    }

    .no-supplier-button-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;
    }
}


.tender-add {
    height: var(--main-height);
    display: flex;
    flex-direction: row;
    gap: 1rem;


    .display-none {
        display: none;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }


    .step-one {
        height: var(--main-height);
        width: 400px;

        @media (max-width:1028px) {
            width: 100%;
        }

        .step-one-container {
            width: 400px;
            height: var(--main-height);
            padding: 1rem;

            display: flex;
            flex-direction: column;
            gap: .5rem;


            border: var(--grey-border);
            border-radius: var(--button-radius);
            opacity: 1;
            transition: opacity 0.5s ease-in-out;
            overflow: hidden;

            @media (max-width:1028px) {
                width: 100%;
            }

            .step-one-top {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: .5rem;
                // margin-bottom: .3rem;

                .title-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                    .title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: .3rem;
                        color: var(--text-color);
                    }
                }
            }

            .step-one-form-container {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: .3rem;

                overflow: auto;

                label {
                    min-width: 123px;
                    font-size: .7rem;
                    color: var(--text-color);
                }

                input,
                .input-div input,
                textarea,
                button,
                select {
                    flex: 1;
                    font-size: .7rem;
                    font-weight: bold;
                    padding: .2rem .5rem;
                    outline: none;
                }

                select {
                    @include select-box;
                    flex: 1;
                }

                .input-standart {

                    .input-select-box {
                        flex: 1;

                        input,
                        select {
                            width: 50%;
                        }
                    }

                    .input-price {
                        flex: 1;

                        input {
                            padding: 5px 10px;
                            padding-left: 2rem;
                        }
                    }

                    input[type="date"] {
                        background-color: var(--select-background-color);
                        color: var(--select-color);
                    }

                    .basic-multi-select {
                        flex: 1;
                        font-size: .7rem;

                    }


                    textarea {
                        @include input-box-light;
                        flex: 1;
                    }


                }

                .documan {
                    align-items: flex-start;

                    .upload-img-btn {
                        width: 75%;
                        flex: 1;

                        button {
                            width: 100%;
                        }
                    }

                    .documan-container,
                    .upload-image-container {
                        width: 75%;
                        flex: 1;
                        // height: 140px;
                    }
                }

                input[type="date"] {
                    background-color: #535B6A;
                    color: #fff;
                }

                .note-indent {
                    text-indent: 7px;
                }
            }
        }
    }

    .step-two {
        height: var(--main-height);
        flex: 1;

        .step-two-container {
            flex: 1;
            height: var(--main-height);
            padding: 1rem;

            border: var(--grey-border);
            border-radius: var(--button-radius);

            display: flex;
            flex-direction: column;
            gap: .5rem;

            .step-two-top {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: .5rem;
                // margin-bottom: .3rem;

                .title-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                    .title {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: .3rem;
                        color: var(--text-color);
                    }
                }

                .step-two-top-right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                }
            }

            .supplier-table {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: .5rem;
                height: 100%; /* Ana container'ın yüksekliği ayarlandı */
            
                .table-one {
                    flex: 1;
                    transition: height 0.3s; /* Geçiş efekti eklendi */
            
                    &:hover {
                        height: 75%; /* Tablo 1 hover durumunda boyutu ayarlandı */
                    }
                }
            
                .table-two {
                    height: 25%;
                    transition: height 0.3s; /* Geçiş efekti eklendi */
            
                    &:hover {
                        height: 75%; /* Tablo 2 hover durumunda boyutu ayarlandı */
                    }
    
                }
            }
        }
    }

    // Media Query
    @media screen and (max-width: 1028px) {
        display: flex;
        flex-direction: column;

        overflow: auto;

        .step-one {
            width: 100%;
            height: max-content;

            .step-one-container {
                width: 100%;
                position: relative;
            }
        }
    }
}


.no-supplier-tender-add {

    .step-one {
        height: calc(var(--main-height) - 50px);
        overflow: hidden;
        opacity: 0.7;
        pointer-events: none;

        .step-one-container {
            height: calc(var(--main-height) - 50px);
        }
    }

    .step-two {
        height: calc(var(--main-height) - 50px);
        overflow: hidden;
        opacity: 0.7;
        pointer-events: none;

        .step-two-container {
            height: calc(var(--main-height) - 50px);
        }
    }
}
@import "../../../styles/variables";

.video-detail-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.314);

    display: flex;
    justify-content: center;
    align-items: center;

    .video-detail-modal-main {
        position: relative;
        width: 60%;
        height: 70vh;
        border: 5px solid rgb(198, 197, 197);
        border-radius: var(--button-radius);
        background-color: black;

        display: flex;
        flex-direction: column;

        .modal-title-container {
            z-index: 1;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            opacity: 0.5;

            &:hover {
                opacity: 1;
                transition: opacity .2s ease-in-out;
                background-color: rgba(0, 0, 0, 0.408);
            }

            .title {
                width: 100%;
                height: 100%;
                color: white;
                padding: .5rem;
                font-size: 1.2rem;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .data-container {
            width: 100%;
            flex: 1;

            video {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

        }

        .video-detail-close-btn,
        .video-detail-next-btn,
        .video-detail-prev-btn {
            z-index: 2;
            width: 40px;
            height: 40px;
            border-radius: var(--button-radius);
            background-color: rgb(198, 197, 197);
            color: black;
            font-weight: bold;
            font-size: 1.5rem;
            border: none;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.663);

            &:hover {
                cursor: pointer;
                box-shadow: 0 0 5px white;
            }
        }

        .video-detail-close-btn {
            position: absolute;
            top: -13px;
            right: -13px;
            width: 30px;
            height: 30px;
            font-size: 1rem;
            color: rgb(249, 37, 37);
            border-radius: 50%;

            &:hover {
                box-shadow: 0 0 5px red;
            }
        }

        .modal-buttons {
            width: 100%;
            height: 50px;
            padding: .5rem;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }


    }
}
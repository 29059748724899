@import '../../styles/variables';

.companyies-applying-main {
    height: var(--main-height);
    display: grid;
    grid-template-rows: 60px 1fr;
    grid-template-areas:
        "edit-box"
        "company-table"
    ;
    gap: .5rem;

    .companyies-applying-edit-box {
        // border: 1px solid black;
        grid-area: edit-box;
        width: 100%;

        .box-top {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            overflow: auto;

            .box-title {
                display: flex;
                flex-direction: row;
                align-items: center;
                color: var(--text-color);

                i {
                    font-size: 1.3rem;
                    margin-right: .4rem;
                }

                .approved-message {
                    font-size: .9rem;
                    font-weight: bold;
                }
            }

            .box-buttons {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;
                padding-right: 1rem;


                .box-buttons-select-container {
                    font-size: .8rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    label {
                        color: var(--text-color)
                    }

                    select {
                        @include select-box;
                        padding: .4rem;
                    }
                }
            }
        }
    }

    .companyies-applying-table {
        // border: 1px solid red;
        grid-area: company-table;
        width: 100%;

    }

}
.first-time-offerex-modal-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 450px;
  overflow: auto;
  padding: 0.5rem;
}
.first-time-offerex-modal-container .title {
  text-align: center;
}
.first-time-offerex-modal-container p {
  font-size: 1.1rem;
}/*# sourceMappingURL=firstTimeOfferexModal.css.map */
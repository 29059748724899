@import "../../styles/variables";

.notification-container {
    height: 100%;
    display: grid;
    grid-template-rows: 60px calc(var(--main-height) - 60px - 40px - 1rem) 40px;
    grid-template-areas:
        "edit-box"
        "notification-main"
        "pagination"
    ;
    gap: .5rem;
    overflow: auto;

    @media (max-width:768px) {
        grid-template-rows: 100px calc(var(--main-height) - 100px - 40px - 1rem) 40px;

    }

    .notification-edit-box {
        grid-area: edit-box;
        width: 100%;
    }

    .notification-main {
        grid-area: notification-main;

        width: 100%;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        overflow: auto;
        padding: 1rem;
        border: var(--grey-border);
        border-radius: var(--button-radius);
    }

    .pagination {
        grid-area: pagination;

    }

}
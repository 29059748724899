@import '../../../../styles/variables';

.button-text-style {
    border: none;
    text-shadow: var(--text-shadow);
    color: var(--text-color);
    background: none;
    padding: .4rem .3rem;
    border-radius: 10px 10px 0 0;
    width: max-content;

    i {
        margin-right: .3rem;
    }


    &:hover {
        cursor: pointer;
        font-weight: 700;
        color: var(--text-hover-color);
        fill: var(--text-hover-color);
    }

}

.button-active {
    font-weight: 700;
    background-color: var(--button-background-color);
    color: var(--button-color);
}
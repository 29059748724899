.tutorial-modal-container {

    display: flex;
    flex-direction: column;
    gap: 2rem;

    .title {
        // margin-bottom: 1rem;
        text-align: center;
    }

    p {
        font-size: 1.1rem;

        .ask-icon,
        .howtowork-text {
            font-size: 1.3rem;
            font-weight: bold;
        }
    }
}
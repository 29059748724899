.company-edit-container {
    width: 100%;
    height: var(--main-height);
    display: grid;
    grid-template-columns: 400px 1fr;
    grid-template-areas: "company-info company-address";
    grid-template-rows: var(--main-height);
    gap: 1rem;
    overflow: auto;

    @media screen and (max-width: 1028px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "company-info"
            "company-address";
        // grid-template-rows: auto;
        // grid-auto-rows: min-content;
    }

    .disabled-container {
        opacity: 0.5;
        pointer-events: none;
    }

    .company-info-container {
        border: var(--grey-border);
        border-radius: var(--button-radius);
        grid-area: company-info;

        display: flex;
        flex-direction: column;
        gap: .5rem;

        padding: 1rem 0;
        overflow: auto;

        .info-top-container,
        .img-container,
        .inputs-container {
            padding: 0 1rem;
        }


        .info-top-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;

            .title-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .3rem;
                color: var(--text-color);
            }
        }

        form {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: .7rem;

            .img-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 1rem;

                .img-box {
                    width: 100px;
                    height: 100px;
                    box-shadow: var(--box-shadow);
                    border-radius: var(--button-radius);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .img-buttons {
                    width: 100%;
                    display: flex;
                    flex-direction: column;

                    gap: .6rem;

                    button {
                        width: 100%;
                        font-size: .7rem;
                        height: 0;
                        padding: .9rem;
                        position: relative;
                        background-color: var(--select-background-color);
                        color: var(--select-color);
                        /* Butonun pozisyonunu relative olarak ayarlayın */

                        label {
                            cursor: pointer;
                            width: 100%;
                            height: 100%;

                            display: flex;
                            justify-content: center;
                            align-items: center;

                            position: absolute;
                            /* Labelin pozisyonunu absolute olarak ayarlayın */
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            .inputs-container {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: .5rem;
                overflow: auto;

                label,
                input {
                    font-size: .7rem;
                }

                label {
                    width: 110px;
                    color: var(--text-color);
                    font-weight: bold;
                }

                input {
                    flex: 1;
                }


                .label-select {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: .3rem;

                    .select-box {
                        flex: 1;

                        select {
                            width: 100%;
                            cursor: pointer;
                            background-color: var(--select-background-color);
                            color: var(--select-color);
                            border: none;
                            outline: none;
                            border-radius: var(--button-radius);
                            box-shadow: var(--box-shadow);
                            padding: .2rem .5rem;
                        }
                    }
                }

            }
        }
    }

    .company-address-container {
        grid-area: company-address;

        border: var(--grey-border);
        border-radius: var(--button-radius);

        display: flex;
        flex-direction: column;
        gap: .5rem;
        padding: 1rem 0;

        .address-top-container,
        form {
            padding: 0 1rem;
        }

        form {
            padding-top: 1rem;
        }

        .address-top-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: .5rem;

            .title-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .3rem;
                color: var(--text-color);

            }

        }

        form {
            flex: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: .6rem;
            overflow: auto;

            label,
            input {
                font-size: .7rem;
            }

            label {
                width: 100px;
                color: var(--text-color);
                font-weight: bold;
            }

            input {
                flex: 1;
            }


            .label-select {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: .3rem;

                .select-box {
                    flex: 1;

                    select {
                        width: 100%;
                        cursor: pointer;
                        background-color: var(--select-background-color);
                        color: var(--select-color);
                        border: none;
                        outline: none;
                        border-radius: var(--button-radius);
                        box-shadow: var(--box-shadow);
                        padding: .2rem .5rem;
                    }
                }
            }
        }

    }
}
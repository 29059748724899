.rating-container {
    width: 100vw;
    height: 100vh;
    z-index: 999999;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


    background-color: rgba(0, 0, 0, 0.562);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .rating-main {
        background-color: rgb(255, 255, 255);
        padding: 1.5rem;
        border-radius: var(--box-radius);
        min-width: 400px;

        h3 {
            margin-bottom: 10px;
        }

        .rating-item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            label {
                margin-right: 10px;
                width: 140px;
            }

            input {
                flex: 1;
                padding: 5px;
                border: 1px solid #ccc;
                border-radius: 5px;
            }
        }

        button {
            padding: 10px 20px;
            background-color: #337ab7;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #23527c;
            }
        }
    }

}
@import "../../styles/variables";

.user-support-request-container {
    height: var(--main-height);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .support-edit-box {
        height: 60px;

        @media (max-width:768px) {
            height: max-content;
            padding: 1rem 0;

        }
    
    }

    .user-support-request-main {
        flex: 1;
        overflow: auto;

        h4 {
            width: 100%;

        }

        .date {
            float: right;
        }

        // Talep kutusu bileşeni stil ve tasarımı
        .ticket-box {
            width: 99%;
            margin-bottom: 24px;
            padding: 16px;

            background-color: #e4f6fc;
            background-color: #c4c4c4b7;

            border-radius: var(--button-radius);
            box-shadow: var(--box-shadow);

            display: flex;
            flex-direction: column;
            gap: 1rem;

            @include text-font-size;

        }

        .photo-modal-container {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            background-color: rgba(0, 0, 0, 0.397);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1020;

            .photo-box {
                width: 80%;
                height: 80%;
                border-radius: 10px;
                overflow: hidden;
                border: 5px solid black;
                background-color: black;
                position: relative;

                .ticket-image {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                .close-button {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    font-size: .9rem;
                    position: absolute;
                    top: 0;
                    right: 0;

                    &:hover {
                        cursor: pointer;
                        opacity: .7;
                    }
                }
            }

        }
    }

    .pagination {
        height: 40px;
    }
}
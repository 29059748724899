.custom-select {
    width: 100%;

    select {
        width: 100%;
        cursor: pointer;
        background-color: var(--button-background-color);
        color: var(--button-color);
        border: none;
        outline: none;
        border-radius: var(--button-radius);
        box-shadow: var(--box-shadow);
        padding: .2rem .5rem;


    }
}
@import "../../../../styles/variables";

.card-container {
    height: 100%;
    border: var(--grey-border);
    border-radius: var(--button-radius);
    padding: .5rem;

    .card-top {
        height: 20%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: .3rem;


        .card-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .3rem;
            color: var(--text-color);

            i {
                @include title-icon-font-size;
            }

            h5 {
                @include title-font-size;
            }

        }

        .card-buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            button {
                width: max-content;
                height: max-content;
                padding: .5rem;
                border-radius: .4rem;

                background-color: var(--button-background-color);
                color: var(-button-color);

                i {
                    font-size: .6rem;

                }
            }
        }
    }

    .card-main {
        height: 80%;
        overflow: auto;
        color: var(--text-color);


        ul {
            height: 100%;
            list-style-type: none;
            padding: .2rem .5rem;
            padding-top: .5rem;
            display: flex;
            flex-direction: column;
            gap: .1rem;

            li {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: .5rem;

                p {
                    @include text-font-size;

                }
            }
        }
    }
}
@charset "UTF-8";
.settings-container {
  height: 100%;
}
.settings-container form {
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: auto;
}
.settings-container form .app-settings,
.settings-container form .theme-settings {
  flex: 1;
  min-width: 400px;
  border: var(--grey-border);
  border-radius: 10px;
  padding: 1rem;
}
.settings-container form .app-settings h4,
.settings-container form .theme-settings h4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color);
  font-weight: 800;
  margin-bottom: 1rem;
  text-shadow: var(--text-shadow);
}
.settings-container form .app-settings h4 svg,
.settings-container form .theme-settings h4 svg {
  width: 20px;
  height: 20px;
  fill: var(--text-color);
  margin-right: 0.5rem;
  filter: drop-shadow(10px 10px 12px rgba(86, 88, 92, 0.836));
}
.settings-container form .app-settings .settings-boxes,
.settings-container form .theme-settings .settings-boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* Başlıklar */
}
.settings-container form .app-settings .settings-boxes h5,
.settings-container form .theme-settings .settings-boxes h5 {
  color: var(--text-color);
  font-size: 0.85rem;
  font-weight: 500;
}
.settings-container form .app-settings .settings-boxes .settings-inputs,
.settings-container form .theme-settings .settings-boxes .settings-inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  color: var(--text-color);
}
.settings-container form .app-settings hr,
.settings-container form .theme-settings hr {
  border: 2px solid var(--text-color);
  height: 15px;
  margin: 0 0.3rem;
}/*# sourceMappingURL=settingPage.css.map */
@import "../../../../styles/variables";

.medium-table-container {
    height: 100%;
    border: var(--grey-border);
    border-radius: var(--button-radius);
    padding: .5rem;
    padding-bottom: 0;

    .table-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: .5rem;
        margin-bottom: .3rem;

        .title-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .3rem;
            color: var(--text-color);

            i {
                @include title-icon-font-size;
            }

            h5 {
                @include title-font-size;
            }
        }

        .buttons {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            button {
                width: max-content;
                height: max-content;
                padding: .5rem;
                border-radius: .4rem;

                background-color: var(--button-background-color);
                color: var(-button-color);

                i {
                    font-size: .6rem;
                }
            }
        }
    }

    .table-main {
        height: 80%;
        height: max-content;
        height: 200px;
        height: calc(var(--main-height) - 40vh - 3rem);
    }
}
@import '../../../../styles/variables';

.button-white-login-style {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background-color: var(--button-background-color);
    color: var(--button-color);
    box-shadow: var(--box-shadow);

    border-radius: .5rem;
    border: none;
    padding: .5rem;
    font-weight: 700;
    width: 100%;

    &:hover {
        cursor: pointer;
        background-color: var(--button-background-hover-color);
    }

    &:hover svg {
        opacity: 1;
    }

    &:hover span {
        opacity: 1;
    }

    i {
        font-size: 1.2rem;
    }

    svg {
        fill: var(--button-color);
        width: 20px;
        height: 20px;

        opacity: .5;
    }

    span {
        padding-left: .3rem;
        opacity: .5;
    }
}

.button-white-opacity {
    opacity: .8;

    &:hover{
        opacity: 1;
    }
}

.button-white-opacity-active{
    opacity: 1;

    svg {
        opacity: 1;
    }

    span {
        opacity: 1;
    }

    &:hover{
        color: rgb(48, 48, 48);
    }
}
.button-white-login-style:disabled{
    cursor: no-drop;
}
@import '../../styles/variables';

.documan-container {
    width: 75%;

    input {
        display: none;
    }

    button {
        width: 100%;
        font-weight: bold;
        background-color: var(--select-background-color);
        color: var(--select-color);
        padding: .3rem;

        box-shadow: var(--box-shadow);
        border-radius: var(--button-radius);
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

    .documan-detail-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        gap: .3rem;
        padding-top: .3rem;

        .documan-detail {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: .6rem;
            color: var(--text-color);

            a {
                color: var(--text-color);
            }

            button {
                width: max-content;
                font-size: .6rem;
                padding: .1rem .3rem;
                background-color: var(--select-background-color);
                color: var(--select-color);
                margin-right: .2rem;

                box-shadow: var(--box-shadow);
                border-radius: var(--button-radius);
                border: none;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
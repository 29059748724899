@import '../../../../styles/variables';

.supplier-add-container {
    height: var(--main-height);

    display: grid;
    grid-template-rows: max-content max-content 1fr;
    grid-template-areas:
        "add-box"
        "invite-box"
        "invitations-box"
    ;
    gap: .5rem;
    overflow: auto;

    .supplier-add-box {
        grid-area: add-box;
    }

    .supplier-invite-box {
        grid-area: invite-box;
    }

    .supplier-invitations-box {
        grid-area: invitations-box;
    }

    .supplier-add-box,
    .supplier-invite-box,
    .supplier-invitations-box {
        display: grid;
        grid-template-rows: 40px 1fr;
        grid-template-areas:
            "title"
            "inputs"
        ;
    }

    .box-top {
        grid-row: title;
        min-height: max-content;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

    }

    .box-title {
        display: flex;
        flex-direction: row;
        align-items: center;


        i {
            font-size: 1rem;
            margin-right: .5rem;
            text-shadow: var(--text-shadow);
            color: var(--text-color);
            @include title-icon-font-size;
        }

        h4 {
            text-shadow: var(--text-shaadow);
            color: var(--text-color);
            font-size: .7rem;

            @include title-font-size;

        }

        button {
            width: max-content;
            padding: .2rem .5rem;
            margin-right: .5rem;

            i {
                font-size: 1rem;
                margin-right: 0;
            }
        }
    }

    .box-buttons {
        button {
            // font-size: .7rem;
            height: max-content;
            border-radius: 5px;
            padding: .2rem .5rem;
        }
    }

    .box-main {
        grid-area: inputs;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        gap: .5rem;
        padding: 1rem;
        border: var(--grey-border);
        border-radius: var(--button-radius);

        .label-input-info {

            label,
            input,
            span {
                @include label-input-font-size;
            }
        }

        .add-box-search-container {
            width: 100%;
            position: relative;

            .search-data-box {
                min-width: 45%;
                height: auto;

                z-index: 999999999;
                position: absolute;
                left: 105px;
                top: 40px;

                border-radius: 10px;
                background-color: rgb(243, 243, 243);
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.24);

                list-style-type: none;

                @media (max-width:1028px) {
                    right: -10px;
                    left: 80px;
                }

                @media (max-width:768px) {
                    left: -10px;
                }

                li {
                    padding: .5rem 1rem;

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(223, 223, 223);
                    }
                }

            }
        }
    }


    .invitations-box-main {
        grid-area: inputs;

        min-height: 140px;
    }

}